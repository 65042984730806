import React, { Component } from 'react';
import {Button, CircularProgress} from "@material-ui/core";
import axios from 'axios';

import { CardElement, injectStripe } from "react-stripe-elements";
import {v4} from 'uuid';
import urlConstants from "../../variables/urlConstants";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.idempotencyKey = v4();
    this.state = {
      errorMessage: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      errorMessage: 'Paying...'
    });

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');
    try {
      let clientSecret;
      let result;
      if (this.props.type === 'user') {
        const paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        });
        const res = await axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/paymentIntent', {
          transactionId: this.props.transactionId,
          paymentMethodId: paymentMethod.paymentMethod.id
        });
        clientSecret = res.data.clientSecret;
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement
          }
        });
      } else {
        clientSecret = this.props.clientSecret;
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement
          }
        });
      }
      if (result.error) {
        this.setState({
          errorMessage: result.error.message
        });
        this.idempotencyKey = v4();
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          this.setState({
            errorMessage: 'Payment Succeeded!'
          });
        }
        this.props.successCallback();
      }
    } catch(err) {
      this.setState({
        errorMessage: 'Please try again later'
      });
      this.idempotencyKey = v4();
    }
  }

  render() {
    const { stripe } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement />
        <Button type='submit' disabled={!stripe} >Pay ${this.props.balance}</Button>
        {this.state.errorMessage}
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
