import React, { Component } from 'react';
import {NavLink, Redirect} from "react-router-dom";
import axios from 'axios';

import urlConstants from '../variables/urlConstants';
import logo3 from "./iAuthoLogo.png";
import login from "./login.png";
import footerlogo from "./footer-logo.png";


class ResetRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      email: '',
      routePrepend: '',
    };
    if (this.props.type === 'insurance') {
      this.state.routePrepend = '/insurance';
    }

    this.submitRequest = this.submitRequest.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  submitRequest(e) {
    e.preventDefault();
    axios.post(urlConstants.BASE_URL + '/requestReset', {
      email: this.state.email,
      type: this.props.type
    })
    .then(() => {
      alert("An email has been sent to reset your password. Please follow those instructions");
      this.setState({
        redirect: true
      });
    })
    .catch(err => {
      if (err && err.response && err.response.status >= 400 && err.response.status < 500) {
        alert(this.props.type + " user not found. Make sure that you're using the right email.");
      } else {
        alert("Unable to reset password at this time. Please try again later.");
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" push={true} />
    }

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left_side">
                <img src={login}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_side_form">
                <div className="login_form">
                  <img src={footerlogo}/>
                  <h2>Reset Password</h2>
                  <div className="FormCenter">
                    <form className="FormFields" onSubmit={this.submitRequest}>
                      <div className="FormField">
                        <label className="FormField__Label" htmlFor="email">Email </label>
                        <input required pattern=".+@.{2,}" type="text" id="email" className="FormField__Input"
                               placeholder= "Email Address" name="email" value={this.state.email} onChange = {(e) => this.handleChange(e)}/>
                      </div>
                      <div className="FormField">
                        <button className="FormField__Button" type='submit' >Request Password Reset</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetRequestForm;
