import React, { Component } from 'react';
import './App.css';
import frank from "../assets/img/faces/frank_perez.jpeg";
import victor from '../assets/img/faces/victor_lee.jpg';
import waqas from "../assets/img/faces/waqas_khalid.png";
import richard from '../assets/img/faces/richard_horning.png';
import manny from '../assets/img/faces/manny_reyes.png';

class AboutPage extends Component {
  constructor(props) {
    super(props);
  }

  

  render() {
    return (
      <div>
        
        <div className="container about_name">
          <div className="row align-items-center">
          <div className="col-md-4">
            <h1 className="title">Advisors</h1>
          </div>
            <div className="col-md-4">
              <div className="img__wrap">
                <img src={richard} alt="customer icon" />
                <h2>Richard Horning</h2>
              </div>
              
            </div>
            <div className="col-md-4">
              <div className="img__wrap">
                <img src={manny} alt="customer icon" />
                <h2>Manny Reyes</h2>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default AboutPage;