import React, { Component } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Modal from 'react-modal';

import profileIcon from './profileIcon.png';
import vehicleIcon from './vehicleIcon.png';
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import BugReport from "@material-ui/icons/BugReport";
// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Search from "@material-ui/icons/Search";
import axios from "axios";
import PDFDocument from 'pdfkit-browserify';
import blobStream from 'blob-stream-browserify';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import urlConstants from '../../variables/urlConstants';
import Typography from "../Typography/Typography";
import ReactToPrint from "react-to-print";
import NewTransaction from "../Transaction/NewTransaction";
import ProfileModal from "../ProfileModal/ProfileModal";
import VehicleInfoModal from "../VehicleInfoModal/VehicleInfoModal";
import LinkedUsersModal from "../LinkedUsersModal/LinkedUsersModal";
import {createTimelineReleasePDF} from "../../handlers/pdfGenerator";
import print_logo from '../../assets/img/print_logo.jpg';
import lock_logo from '../../assets/img/lock_logo.jpg';
import share_logo from '../../assets/img/share_logo.jpg';
import x_button from '../../assets/img/x-button.png';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from "@material-ui/core/IconButton";
import ChatLogo from '../../assets/img/chat_logo.png';
import {Avatar} from "@material-ui/core";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.customerVinMap = new Map();
    this.vinSet = new Set();
    this.vinROMap = new Map();
    this.state = {
      value: 0,
      posts: [],
      lastEvaluatedKey: undefined,
      currentTransactionId: '',
      customerInfo: undefined,
      searchText: '',
      vinChoiceList: [],
      vinDetailsMap: new Map(),
      submitting: false,
      refresh: false,
      blob: null,
      modalComponent: '',
      timelineReleaseEnabled: false,
      customerIdNameMap: new Map(),
      refreshTimeline: false,
    };
    if (window.location.hash && window.location.hash.split('?')[1]) {
      let searchParams = new URLSearchParams(window.location.hash.split('?')[1]);
      let vin = searchParams.get('vin');
      this.openDetails(vin).then(() => {
        let vehicleModalOpen = searchParams.get('vehicleModalOpen');
        let profileModalOpen = searchParams.get('profileModalOpen');
        if (vehicleModalOpen) {
          this.openVehicleModal();
        } else if (profileModalOpen) {
          this.openUserModal();
        }
      })
    }
    this.timelineRef = null;
    this.refreshTransactions = this.refreshTransactions.bind(this);
    this.getArrivingVehicles = this.getArrivingVehicles.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {

    this.unlisten = this.props.history.listen((location, action) => {
      if (location.search === "") {
        this.setState({
          selectedVin: ''
        })
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    // Modal.setAppElement('#dashboardTable');
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/icon')
      .then(res => {
        this.setState({
          logoSrc: res.data,
        });
      })
      .catch(err => {
        if (err && err.response && err.response.status === 404) {
          this.setState({
            missingLogo: true,
          });
        }
      });
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/customerVehicles')
      .then(res => {
        let tempPosts = [];
        for (let i = 0; i < res.data.Items.length; i++) {
          this.addTransactionWithoutDuplicate(tempPosts, res.data.Items[i]);
        }
        this.setState({
          posts: tempPosts,
          lastEvaluatedKey: res.data.lastEvaluatedKey
        });
        this.getAllCustomerNames(tempPosts);
      });

    if (this.props.type !== 'user') {
      this.setState({
        arrivingVehiclePolling: setInterval(this.getArrivingVehicles, 30000)
      });
      window.onfocus = () => {
        this.setState({
          arrivingVehiclePolling: setInterval(this.getArrivingVehicles, 30000)
        });
      }
      window.onblur = () => {
        clearInterval(this.state.arrivingVehiclePolling)
      }
    }

    this.getArrivingVehicles();

    this.setState({
      transactionPolling: setInterval(this.refreshTransactions, 30000),
    });
    window.onfocus = () => {
      this.setState({
        transactionPolling: setInterval(this.refreshTransactions, 30000)
      });
    };
    window.onblur = () => {
      clearInterval(this.state.transactionPolling);
    };

    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      clearInterval(this.state.transactionPolling);
      if (this.state.arrivingVehiclePolling) {
        clearInterval(this.state.arrivingVehiclePolling);
      }
      // Chrome requires returnValue to be set.
      return;
    });

    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/info').then(async res => {
      this.setState({
        userEmail: res.data.email,
      })
    });
  }

  addTransactionWithoutDuplicate(arr, item) {
    if (!this.vinSet.has(item.vin)) {
      arr.push(item);
      this.vinSet.add(item.vin);
    }
    if (!this.customerVinMap.has(item.vin) && item.userType === 'user') {
      this.customerVinMap.set(item.vin, item.userId);
      this.vinROMap.set(item.vin, item.role);
    }
  }

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.transactionPolling);
    if (this.state.arrivingVehiclePolling) {
      clearInterval(this.state.arrivingVehiclePolling);
    }
    window.onfocus = undefined;
    window.onblur = undefined;
  }

  getArrivingVehicles() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/checkedInUsers')
    .then(res => {
      this.setState({
        arrivingData: res.data
      });
    })
  }

  addToDashboardAndRemove(vin) {
    this.addToDashboard(vin).then(() => {
      axios.delete(urlConstants.BASE_URL + '/' + this.props.type + '/checkedInUsers?vin=' + encodeURIComponent(vin))
      .then(() => {
        let newArrivingData = this.state.arrivingData.slice();
        for (let i = 0; i < newArrivingData.length; i++) {
          if (newArrivingData[i].vin === vin) {
            newArrivingData.splice(i, 1);
            break;
          }
        }
        this.setState({
          arrivingData: newArrivingData
        });
        // this.requestFullTimelineAccess()
      })
    })
  }

  removeCheckedInUser(vin) {
    axios.delete(urlConstants.BASE_URL + '/' + this.props.type + '/checkedInUsers?vin=' + encodeURIComponent(vin))
    .then(() => {
      let newArrivingData = this.state.arrivingData.slice();
      for (let i = 0; i < newArrivingData.length; i++) {
        if (newArrivingData[i].vin === vin) {
          newArrivingData.splice(i, 1);
          break;
        }
      }
      this.setState({
        arrivingData: newArrivingData
      })
    })
  }

  refreshTransactions() {
    this.vinSet = new Set();
    this.customerVinMap = new Map();
    var transactionItems = [];
    var refreshTransactionsHelper = (isFirstTime, lastEvaluatedKey) => {
      var queryParams = '';
      if (!isFirstTime && lastEvaluatedKey === this.state.lastEvaluatedKey) {
        return Promise.resolve();
      }
      if (lastEvaluatedKey !== undefined) {
        queryParams = '?lastEvaluatedKey=' + encodeURIComponent(lastEvaluatedKey);
      }
      return axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/customerVehicles' + queryParams)
      .then(res => {
        for (let i = 0; i < res.data.Items.length; i++) {
          this.addTransactionWithoutDuplicate(transactionItems, res.data.Items[i]);
        }
        return refreshTransactionsHelper(false, res.data.lastEvaluatedKey);
      });
    };
    refreshTransactionsHelper(true, undefined)
    .then(() => {
      this.setState({
        posts: transactionItems
      });
    });
  }

  loadMore() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/customerVehicles?lastEvaluatedKey=' +
      encodeURIComponent(this.state.lastEvaluatedKey))
    .then(res => {
      let newItems = [];
      for (let i = 0; i < res.data.Items.length; i++) {
        this.addTransactionWithoutDuplicate(newItems, res.data.Items[i]);
      }
      this.setState({
        posts: this.state.posts + newItems,
        lastEvaluatedKey: res.data.lastEvaluatedKey
      });
    });
  }

  handleChangeIndex(index) {
    this.setState({ value: index });
  }

  getVehicleDetails(vin) {
    if (this.state.vinDetailsMap.has(vin)) {
      return new Promise(resolve => {
        resolve({data: { "Item": this.state.vinDetailsMap.get(vin) } });
      })
    }
    return axios.get(urlConstants.BASE_URL + '/vehicle/vin?vin=' + encodeURIComponent(vin))
      .then(res => {
        if (res.data.Item && res.data.Item.details) {
          let newMap = this.state.vinDetailsMap;
          newMap.set(vin, res.data.Item);
          this.setState({
            vinDetailsMap: newMap
          })
        } else if (res.data.Items && res.data.Items.length === 1) {
          let newMap = this.state.vinDetailsMap;
          newMap.set(vin, res.data.Items[0]);
          this.setState({
            vinDetailsMap: newMap
          })
        } else if (res.data.Items && res.data.Items.length > 1) {
          this.setState({
            vinChoiceList: res.data.Items.map(elem =>
              <a onClick={() => this.openDetails(elem.vin)}>{elem.vin}</a>
            )
          });
        }
        return res;
      });
  }

  openDetails(vin) {
    vin = vin.toUpperCase();
    this.setState({
      customerInfo: null
    });
    if (this.props.type !== 'user') {
      this.setState({
        timelineRelease: null,
        timelineReleaseExpired: false,
        timelineReleaseEnabled: true
      });
      axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/timelineRelease?vin=' + encodeURIComponent(vin))
      .then(res => {
        if (!res.data.expirationTime) {
          this.setState({
            timelineRelease: false,
            timelineReleaseEnabled: !res.data.pending
          });
        } else {
          this.setState({
            timelineRelease: true,
            timelineReleaseExpired: res.data.expirationTime < new Date()
          });
        }
      })
    } else {
      this.setState({
        timelineRelease: true
      })
    }
    return this.getVehicleDetails(vin)
    .then(res => {
      if (res.data.Item) {
        this.setState({
          details: res.data.Item.details,
          selectedVin: vin,
          licenseNum: res.data.Item.licenseNum,
          miles: res.data.Item.miles
        });
        this.setState({
          vinChoiceList: []
        });
        window.location.hash = window.location.hash.split("?")[0] + "?vin=" + vin
        axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
          encodeURIComponent(res.data.Item.owner) + '&type=unknown')
        .then(res => {
          if (res.data.Item) {
            this.setState({
              customerInfo: res.data.Item
            });
          }
        });
      } else if (res.data.Items) {
        if (res.data.Items.length === 1) {
          this.setState({
            details: res.data.Items[0].details,
            selectedVin: res.data.Items[0].vin
          });
          this.setState({
            vinChoiceList: []
          });
          axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
            encodeURIComponent(res.data.Items[0].owner) + '&type=unknown')
          .then(res => {
            if (res.data.Item) {
              this.setState({
                customerInfo: res.data.Item
              });
            }
          });
        } else {
          this.setState({
            vinChoiceList: res.data.Items.map(elem =>
              <a onClick={() => this.openDetails(elem.vin)}>{elem.vin}</a>
            )
          });
        }
      }
    })
    .catch(err => {
      if (err && err.response && err.response.status === 404) {
        this.setState({
          vinChoiceList: ["No data found for this vin"]
        })
      }
    })
  }

  requestFullTimelineAccess() {
    const params = new FormData();
    params.append('vin', this.state.selectedVin);
    params.append('userType', 'user');
    params.append('email', this.state.customerInfo.email);

    const doc = new PDFDocument();
    // pipe the document to a blob
    const stream = doc.pipe(blobStream());
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/info').then(async res => {
      await createTimelineReleasePDF(doc, res.data.name, res.data.email, this.state.customerInfo.name, this.state.selectedVin, this.state.details);
      stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf');
        params.append('serviceDescription', blob);
        axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/timelineRelease',  params, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(() => {
          alert('Successfully requested access to full timeline');
          this.setState({
            timelineRelease: false,
            timelineReleaseEnabled: false
          });
        })
        .catch(err => {
          alert('Failed to request access to the full timeline')
        })
      })
    })
  }

  openModal(component) {
    this.setState({
      modalComponent: component,
      modalOpen: true
    });
  }

  openTimeline() {
    localStorage.setItem("timelineVin", "")
  }

  updateIcon() {
    var selectedFile = document.getElementById('vehicleIconFile').files;
    if (selectedFile.length === 0) {
      return;
    }
    this.setState({
      submitting: true
    });

    const formData = new FormData();
    formData.append('vin', this.state.selectedVin);
    formData.append('vehicleIcon', selectedFile[0], selectedFile[0].name);

    axios.post(urlConstants.BASE_URL + '/vehicle/vehicleIcon', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(() => {
      this.setState({
        refresh: true
      });
      var fr = new FileReader();
      fr.onload = () => {
        this.setState({
          blob: fr.result
        })
      }
      fr.readAsDataURL(selectedFile[0]);
    })
    .catch(err => {
      alert("Please try again. Couldn't upload vehicle icon");
    })
    .finally(() => {
      this.setState({
        submitting: false
      })
    })
  }

  refreshTimeline() {
    this.setState({
      refreshTimeline: !this.state.refreshTimeline
    })
  }

  removeFromDashboard(vin) {
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/removeFromDashboard/' + encodeURIComponent(vin))
    .then(() => {
      this.setState({
        posts: this.state.posts.filter(el => el.vin !== vin)
      });
      this.vinSet.delete(vin);
    })
    .catch(err => {
      window.alert("Something went wrong. Please try again later.");
    });
  }

  addToDashboard(vin) {
    return axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/addToDashboard/' + encodeURIComponent(vin))
    .then(res => {
      let oldPosts = this.state.posts.slice();
      if (!res.data.transactionTime) {
        oldPosts.splice(0, 0, res.data);
      } else {
        for (let i = 0; i < oldPosts.length; i++) {
          if (oldPosts[i].transactionTime < res.data.transactionTime) {
            oldPosts.splice(i, 0, res.data);
            break;
          }
        }
      }
      this.setState({
        posts: oldPosts
      });
    })
    .catch(err => {
      window.alert("Something went wrong. Please try again later.");
    });
  }

  openUserModal() {
    this.setState({
      retrieveUserMessage: 'Fetching user data...'
    });
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupByEmail?type=unknown' +
      '&email=' + encodeURIComponent(this.state.customerInfo ? this.state.customerInfo.email : ''))
    .then(res => {
      let jsonResult = res.data.Items[0];
      jsonResult.email = this.state.customerInfo.email;
      this.setState({
        userDetailsFound: jsonResult,
        signUpWarning: false,
        userType: res.data.userType
      });
    })
    .catch(err => {
      if (err && err.response && err.response.status === 404 && err.response.data) {
        let jsonResult = err.response.data.Item;
        jsonResult.email = this.state.customerInfo.email;
        this.setState({
          userDetailsFound: jsonResult,
          signUpWarning: true,
          userType: 'user'
        })
      } else {
        this.setState({
          userType: 'user'
        })
      }
    })
    .finally(() => {
      this.setState({
        retrieveUserMessage: ''
      });
      if (this.state.userType === 'user') {
        this.openModal(<ProfileModal type={this.props.type} profileInfo={this.state.customerInfo}
                                     targetGroup={this.state.userType}
                                     vin={this.state.selectedVin}
                                     successCallback={data => {
                                       this.setState({
                                         customerInfo: data
                                       });
                                       this.closeModal();
                                     }}/>);
      } else {
        this.openModal(<ProfileModal type={this.props.type} profileInfo={this.state.customerInfo}
                                     viewOnly={true} targetGroup={this.state.userType}
                                     successCallback={data => {
                                       this.setState({
                                         customerInfo: data
                                       });
                                       this.closeModal();
                                     }}/>);
      }
    })
  }

  getVehicleModalComponent() {
    let extractedVehicle = {
      vin: this.state.selectedVin,
      licenseNum: this.state.licenseNum,
      details: this.state.details,
      miles: this.state.miles
    }
    return <VehicleInfoModal type={this.props.type} vehicleInfo={this.state.vinDetailsFound}
                    extractedVehicleInfo={extractedVehicle}
                    successCallback={data => {
                      let newPosts = this.state.posts.map(e => e);
                      for (let i in newPosts) {
                        if (newPosts[i].vin === this.state.selectedVin) {
                          newPosts[i].details = data.details;
                          newPosts[i].licenseNum = data.licenseNum;
                          break;
                        }
                      }
                      this.setState({
                        details: data.details,
                        selectedVin: data.vin,
                        licenseNum: data.licenseNum,
                        miles: data.miles,
                        posts: newPosts
                      });
                      this.closeModal();
                    }}/>
  }

  openVehicleModal() {
    this.setState({
      retrieveVinMessage: 'fetching vin data...'
    })
    axios.get(urlConstants.BASE_URL + '/vehicle/vin?vin=' + encodeURIComponent(this.state.selectedVin))
    .then(res => {
      let jsonResult;
      if (res.data.Items) {
        jsonResult = res.data.Items[0];
      } else {
        jsonResult = res.data.Item;
      }
      this.setState({
        vinDetailsFound: jsonResult
      });
    })
    .catch(() => {
      this.setState({
        vinDetailsFound: null
      })
    })
    .finally(() => {
      this.openModal(this.getVehicleModalComponent());
      this.setState({
        retrieveVinMessage: ''
      });
    });
  }

  openTransactionModal() {
    this.openModal(<NewTransaction name='Customer' type={this.props.type} targetGroup='user'
                      allowTypeSelection={true} roNumber={this.vinROMap.get(this.state.selectedVin)}
                      vin={this.state.selectedVin}
                      successCallback={() => {
                        this.refreshTransactions();
                        this.refreshTimeline();
                        this.closeModal();
                      }}
                      userEmail={this.state.customerInfo ? this.state.customerInfo.email : ""} />)
  }

  openLinkedUsersModal() {
    this.openModal(<LinkedUsersModal type={this.props.type} vin={this.state.selectedVin} />)
  }

  closeModal() {
    window.location.hash = window.location.hash.replace(/[\?&]vehicleModalOpen=[^&]+/, '').replace(/^&/, '?');
    window.location.hash = window.location.hash.replace(/[\?&]profileModalOpen=[^&]+/, '').replace(/^&/, '?');
    this.setState({
      modalOpen: false
    });
  }

  getAllCustomerNames(posts) {
    let newCustomerIdNameMap = new Map();
    let seenIds = new Set();
    let promises = [];
    for (let post of posts) {
      if (post.owner && !seenIds.has(post.owner)) {
        seenIds.add(post.owner);
        promises.push(axios.get(urlConstants.BASE_URL + "/" + this.props.type + "/lookupById?type=user&userId=" + post.owner))
      }
    }
    Promise.allSettled(promises).then(results => {
      for (let i = 0; i < results.length; i++) {
        if (results[i].status === 'fulfilled') {
          let item = results[i].value.data.Item
          newCustomerIdNameMap[item._id] = item.name
        }
      }
      this.setState({
        customerIdNameMap: newCustomerIdNameMap
      })
    })
  }

  shareTimeline() {
    let link = 'Here is the timeline for the vehicle: ' + urlConstants.BASE_URL + '/#/admin/dashboard?vin=' + encodeURIComponent(this.state.selectedVin);
    window.location = 'mailto:?body=' + link + '&subject=Timeline%20for%20' + encodeURIComponent(this.state.selectedVin);
  }

  render() {
    var data = this.state.posts.slice();
    if (this.state.lastEvaluatedKey) {
      data.push({roNumber: null, date: ','});
    }

    let arrivingColumns = [{
      id: 'name',
      Header: 'Name',
      accessor: d => d.customerName
    }, {
      id: 'details',
      Header: 'Details',
      accessor: d => d.vehicleDetails
    }, {
      id: 'plate',
      Header: 'License Plate',
      accessor: d => d.licenseNum
    }, {
      id: 'vin',
      Header: 'VIN',
      accessor: d => d.vin,
      Cell: (props) => {
        if (!props.value) {
          return null
        }
        return <a onClick={() => this.openDetails(props.value)} name={props.value}>{props.value}</a>;
      },
    }, {
      id: 'signIn',
      Header: 'Sign In',
      accessor: d => <button onClick={() => this.addToDashboardAndRemove(d.vin)}>Sign In VIN</button>
    }, {
      id: 'remove',
      Header: 'Remove',
      accessor: d => <button type='submit' onClick={() => this.removeCheckedInUser(d.vin)}><img src={x_button} alt='x-button' height='12'/> Remove</button>
    }];

    let columns = [{
      id: 'name',
      Header: 'Name',
      accessor: d => this.state.customerIdNameMap[d.owner]
    }, {
      id: 'details',
      Header: 'Vehicle Details',
      accessor: d => d.details
    }, {
      id: 'plate',
      Header: 'License Plate',
      accessor: d => d.licenseNum
    }, {
      id: 'vin',
      Header: 'VIN',
      accessor: d => d.vin,
      Cell: (props) => {
        if (!props.value) {
          return null
        }
        return <a onClick={() => this.openDetails(props.value)} name={props.value}>{props.value}</a>;
      },
      filterMethod: (filter, row) => row[filter.id].startsWith(row[filter.id].toUpperCase()) ||
        row[filter.id].endsWith(row[filter.id].toUpperCase()) || React.isValidElement(row['roNumber'])
    }, {
      id: 'date', // Required because our accessor is not a string
      Header: 'Date',
      accessor: d => d.transactionTime, // Custom value accessors!
      Cell: (props) => {
        if (!props.value || props.value === ',') {
          return null;
        }
        return new Date(props.value).toLocaleDateString()
      }
    }];

    if (this.props.type !== 'user') {
      columns.push({
        id: 'transactionStatus',
        Header: 'PAID',
        accessor: d => d.transactionStatus !== 'Pending Payment' && d.transactionStatus !== 'Pending Authorization' ? (d.invoiceAmount ? d.invoiceAmount : 0) : 0
      });
      columns.push({
        id: 'profile',
        Header: 'PROFILE',
        accessor: d => <img className="album-img" src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + d.vin} alt={d.customerName + "'s vehicle"}  width="auto"
                                      height="50" style={{ maxWidth: 150, borderRadius: "20px"}} />
      })
      columns.push({
        id: 'signOut',
        Header: 'Sign Out',
        accessor: d => <button onClick={() => this.removeFromDashboard(d.vin)}>Sign out VIN</button>
      });
    }

    return (
      <div id="dashboardContainer">
        {/*{this.props.type !== 'user' && !this.state.missingLogo && <img src={this.state.logoSrc} alt='logo' />}*/}
        {this.props.type !== 'user' && this.state.missingLogo &&
          <a href='/#/admin/user'>Click here to upload logo</a>}

        {this.state.selectedVin &&
          <GridContainer>
            {/*<div style={{overflow: 'auto', maxHeight: '450px', float: 'left', width: '60%', minWidth: '470px'}}
                 className='overflowScroll'>
              <div>
                <ReactToPrint style
                              trigger={() => <input type='image' alt='Print timeline' src={print_logo} width='36' height='36' />}
                              content={() => this.printRef}/>
                {this.props.type !== 'user' &&
                  <div>
                    {this.state.timelineRelease && !this.state.timelineReleaseExpired && <img src={share_logo} alt='Shared Timeline' onClick={() => this.shareTimeline()} width='8%' style={{float: 'left'}}/>}
                    {!this.state.timelineRelease || this.state.timelineReleaseExpired && <img src={lock_logo} alt='Private Timeline' width='5%' style={{float: 'left'}}/>}
                  </div>
                }
                <GridItem>
                  {this.props.type !== 'user' && this.state.timelineRelease && <p></p>}
                  <div ref={elem => this.printRef = elem} style={{float: 'right', width: '95%'}}>
                    <Typography style={{float: 'left'}} key={this.state.selectedVin} refresh={this.state.refreshTimeline}
                                vin={this.state.selectedVin} type={this.props.type}/>
                  </div>
                </GridItem>
              </div>
            </div>*/}
            <GridItem className="car_infogrid">
              <div className="loop_info">{this.props.type !== 'user' &&
              <button style={{backgroundColor: "#FFFFFF", borderRadius: "5px"}} onClick={() => this.openVehicleModal()} disabled={this.state.submitting}>
                <img src={vehicleIcon} style={{width: "40px", height: "40px" }} /></button>}</div>
                <div className="loop_info"><b>VIN:</b> {this.state.selectedVin}</div>
                <div className="loop_info"><b>Details:</b> {this.state.details ? this.state.details : <span style={{color: "red", fontWeight: 'bold'}}>Missing (Not B.A.R. compliant)</span>}</div>
                <div className="loop_info"><b>License Number:</b> {this.state.licenseNum ? this.state.licenseNum : <span style={{color: "red", fontWeight: 'bold'}}>Missing (Not B.A.R. compliant)</span>}</div>
                <div className="loop_info">
                  {this.state.timelineRelease &&
                  <div>
                    <b>Miles:</b> {this.state.miles ? this.state.miles : <span style={{color: "red", fontWeight: 'bold'}}>Missing (Not B.A.R. compliant)</span>}
                  </div>
                }
                </div>
                
              <div className="loop_info mg_top">{this.props.type !== 'user' &&
              <button style={{backgroundColor: "#FFFFFF", borderRadius: "5px"}} onClick={() => this.openUserModal()} disabled={this.state.submitting}>
                <img src={profileIcon} style={{width: "40px", height: "40px" }} /></button>}</div>
                {this.state.customerInfo &&
                <div className="botton_info">
                  <div className="loop_info"><b>Customer Name:</b> {this.state.customerInfo.name}</div>
                  <div className="loop_info"><b>Customer Email:</b> {this.state.customerInfo.email}</div>
                  <div className="loop_info"><b>Customer Phone:</b> {this.state.customerInfo.phoneNumber ? this.state.customerInfo.phoneNumber :
                    <span style={{color: "red", fontWeight: 'bold'}}>Missing (Not B.A.R. compliant)</span>}</div>
                  <div className="loop_info"><b>Customer Address:</b> {this.state.customerInfo.address ? this.state.customerInfo.address :
                  <span style={{color: "red", fontWeight: 'bold'}}>Missing (Not B.A.R. compliant)</span>}</div>

                  <div className="loop_info"><b>Choose Vehicle Icon: </b><input type="file" accept="image/*" id="vehicleIconFile"
                                                        onChange={() => this.updateIcon()}/></div>

                </div>
                }
                {/*{this.props.type !== 'user' && this.state.customerInfo && this.state.customerInfo.email && !this.state.timelineRelease &&*/}
                {/*  <div>*/}
                {/*    <Button onClick={() => this.requestFullTimelineAccess()} disabled={!this.state.timelineReleaseEnabled}>*/}
                {/*      {this.state.timelineReleaseEnabled ? 'Request Access to Timeline' : 'Pending Access'}</Button>*/}
                {/*  </div>*/}
                {/*}*/}
                {!this.state.customerInfo &&
                <p>No customers found for this vehicle.</p>
                }
                <div className="btn_wpas">
                <Button style={{backgroundColor: "#4ca2f4"}} onClick={() => this.openTransactionModal()}>Start new
                  transaction</Button>

                {this.props.type !== 'user' &&
                  <Button onClick={() => this.openLinkedUsersModal()} >Users Signed In to VIN</Button>
                }
                </div>





            </GridItem>
            <GridItem className="car_infogrid img">
              {
                this.state.refresh ?
                    <img src={this.state.blob} alt="vehicle icon" width="auto" height="auto" style={{maxHeight: 150, maxWidth: 350, borderRadius: "20px"}} />
                    : <img src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + this.state.selectedVin} alt="vehicle icon" width="auto"
                           height="auto" style={{maxHeight: 150, maxWidth: 350, borderRadius: "20px"}} />
              }
              
            </GridItem>
          </GridContainer>

        }


        {/*{this.props.type !== 'user' && <div>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Minimized'
                },
                {
                  tabName: "Arriving Customers",
                  tabContent: (
                    <ReactTable columns={arrivingColumns} data={this.state.arrivingData} filterable={true}
                                defaultPageSize={5}
                                defaultFilterMethod={(filter, row, column) => {
                                  const id = filter.pivotId || filter.id
                                  return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true
                                }} />
                  )
                }
              ]}
            />
          </GridItem>
        </div>}*/}
        <GridContainer id='dashboardTable'>

          <GridItem xs={12} sm={12} md={12}>
            <div className="search-wrapper">
              {this.props.type !== 'user' && <div className="search">
                <CustomInput
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "placeholder": "VIN (or last 8 chars)",
                        "aria-label": "Search"
                      },
                      name: "searchText",
                      value: this.state.searchText,
                      onChange: this.handleChange
                    }}
                    formControlProps={{}}
                />
                <Button color="white" aria-label="edit" justIcon round
                        onClick={() => this.openDetails(this.state.searchText)}>
                  <Search/>
                </Button>
                {this.state.vinChoiceList.length > 0 &&
                <div>
                  <p>Vins found:</p>
                  <ol>{this.state.vinChoiceList}</ol>
                </div>
                }
                {this.state.selectedVin && !this.vinSet.has(this.state.selectedVin.toUpperCase()) &&
                <Button onClick={() => this.addToDashboardAndRemove(this.state.selectedVin)}>Sign In Vehicle</Button>
                }
                {/*{!this.state.timelineRelease && this.state.selectedVin && <p>Please request access to the full timeline to view all transactions</p>}*/}
                {/*{this.state.timelineReleaseExpired && <p>You need to Request Access to Timeline for future events as the authorization has expired</p>}*/}
                {this.state.selectedVin && <Button onClick={() => this.shareTimeline()} className="btn_vehic">Share vehicle timeline</Button>}
              </div>
              }
              <a className="process-btn" href="">In Process</a>
            </div>
            <div>
              <ReactTable columns={columns} data={data} filterable={true}
                          defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true
                          }}/>
            </div>
            {/*
            <CustomTabs
              title=""
              headerColor="primary"s
              tabs={[
                {
                  tabName: "In Process",
                  // tabIcon: BugReport,
                  tabContent: (
                    <ReactTable columns={columns} data={data} filterable={true}
                                defaultFilterMethod={(filter, row, column) => {
                                  const id = filter.pivotId || filter.id
                                  return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true
                                }}/>
                  )
                }
              ]}
            />
            */}
          </GridItem>
        </GridContainer>
        <Modal isOpen={this.state.modalOpen} style={{overlay: {zIndex: 1000}}} >
          <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
          {this.state.modalComponent}
        </Modal>
        <IconButton style={{position: 'fixed', right: '20px', bottom: '20px', backgroundColor: '#279BFB'}}
                    href={'mailto:info@ibottt.com?subject=Support%20Request&body=The%20iBottt%20account%20used%20is%20' + encodeURIComponent(this.state.userEmail ?? '')} >
          <Avatar src={ChatLogo} />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(Dashboard);
