import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-pdf/dist/Page/AnnotationLayer.css';
import urlConstants from '../../variables/urlConstants';
import './Signature.css';

import CanvasDraw from '@win11react/react-canvas-draw';
import SignatureCanvas from 'react-signature-canvas';

import signatureLine from './signature_line.jpg';
import Button from "@material-ui/core/Button";
import logo from "../../layouts/iauthocircle.png";
import NewPayment from "../Payment/NewPayment";
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";

class Signature extends Component {
  constructor(props) {
    super(props);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    let attachments = [];
    if (this.props.location && this.props.location.state && this.props.location.state.transactionId) {
      this.transactionId = this.props.location.state.transactionId;
    } else {
      this.transactionId = this.props.transactionId;
    }
    if (this.props.location && this.props.location.state && this.props.location.state.attachmentLinks) {
      try {
        attachments = JSON.parse(this.props.location.state.attachmentLinks);
      } catch (err) {
        attachments = [this.props.location.state.attachmentLinks];
      }
    } else if (this.props.attachmentLinks) {
      try {
        attachments = JSON.parse(this.props.attachmentLinks);
      } catch (err) {
        attachments = [this.props.attachmentLinks];
      }
    }

    if (window.location.hash.indexOf('/user/') !== -1) {
      this.type = 'user';
    } else if (window.location.hash.indexOf('/facility/') !== -1) {
      this.type = 'facility';
    }
    let firstPdf = null;
    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].toLowerCase().endsWith(".pdf")) {
        firstPdf = urlConstants.BASE_URL + '/' + this.props.type + '/transaction/files/' +
          encodeURIComponent(attachments[i]);
        break;
      }
    }

    this.signed = false;
    this.signatureRefs = [];
    this.pageRefs = [];
    this.state = {
      pdf: firstPdf,
      isSubmitting: false,
      submitSuccessful: false,
      submitError: '',
      numPages: null,
      isSigning: false,
      balance: null,
    };
    this.pdfData = null;
    this.loadingTimer = null;

    this.onPdfLoad = this.onPdfLoad.bind(this);
    this.refreshStatus = this.refreshStatus.bind(this);
  }

  onPdfLoad(pdf) {
    this.setState({
      numPages: pdf.numPages
    });
    pdf.getData().then(data => {
      this.pdfData = data;
    });
  }

  componentDidMount() {
    if (this.transactionId) {
      axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/transaction?transaction=' + this.transactionId)
        .then(res => {
          this.setState({
            transactionStatus: res.data.transactionStatus,
            balance: res.data.balance,
          })
        })
      axios.get(this.state.pdf)
        .then(res => {
          this.setState({
            pdfUrl: res.request.responseURL
          })
        })
    }
    if (this.sigCanvas) {
      this.sigCanvas.clear();
      const ctx = this.sigCanvas.getCanvas().getContext('2d');
      const image = this.imageCanvas;
      image.onload = () => {
        ctx.drawImage(image, 0, -10);
      }
    }
  }

  refreshStatus() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/transaction?transaction=' + this.transactionId)
      .then(res => {
        this.setState({
          transactionStatus: res.data.transactionStatus
        })
      })
  }

  clearSignature() {
    this.signed = false;
    if (this.sigCanvas) {
      this.sigCanvas.clear();
      const ctx = this.sigCanvas.getCanvas().getContext('2d');
      const image = this.imageCanvas;
      ctx.drawImage(image, 0, -10);
    } else {
      for (let i = 0; i < this.signatureRefs.length; i++) {
        this.signatureRefs[i].clear();
      }
    }
  }

  async uploadAuthorization(timelineOptOut) {
    let params = {};
    let headers = { };
    if (this.state.pdf) {
      const pdfDoc = await PDFDocument.load(this.pdfData);
      const pages = pdfDoc.getPages();

      for (let i = 0; i < this.signatureRefs.length; i++) {
        let canvas = this.signatureRefs[i].canvas["drawing"];
        let image = canvas.toDataURL('image/png');
        const pngImage = await pdfDoc.embedPng(image);

        pages[i].drawImage(pngImage, {
          x: 0,
          y: 0,
          width: pngImage.width,
          height: pngImage.height
        });
      }
      if (timelineOptOut === true) {
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        pages[pages.length - 1].drawText('Consumer has chosen to opt out of having their information sold', {
          x: 108,
          y: 242,
          size: 15,
          font: helveticaFont,
          color: rgb(0, 0, 1.0)
        });
      } else if (timelineOptOut === false) {
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        pages[pages.length - 1].drawText('Consumer has chosen to NOT opt out of having their information sold', {
          x: 108,
          y: 242,
          size: 15,
          font: helveticaFont,
          color: rgb(0, 0, 1.0)
        });
      }
      let pdfBytes = await pdfDoc.save();
      const formData = new FormData();
      formData.append('transaction', this.transactionId);
      formData.append('serviceDescription', new Blob([pdfBytes], {type: 'application/pdf'}), 'approve.pdf');
      params = formData;
      headers = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    } else {
      params.transaction = this.transactionId;
      params.signature = this.sigCanvas.toDataURL("image/jpg", 'base64').split(',')[1];
    }

    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/approve', params, headers)
    .then(() => {
      this.setState({
        submitError: null,
        submitSuccessful: true
      });
      if (this.props.successCallback) {
        this.props.successCallback();
      }
      this.refreshStatus();
    })
    .catch(err => {
      if (err && err.response && err.response.status === 400) {
        this.setState({
          submitError: 'Authorize failed. Please check that you have signed.'
        });
      } else {
        console.log(err);
        this.setState({
          submitError: 'Authorize failed. Please try again later.'
        })
      }
    })
    .finally(() => {
      this.setState({
        isSubmitting: false
      });
    })
  }

  async authorize() {
    if (!this.signed) {
      this.setState({
        submitSuccessful: false,
        submitError: 'Authorize failed. Please check that you have signed.'
      });
      return;
    }
    this.setState({
      isSubmitting: true,
      submitSuccessful: false,
      submitError: ''
    });
    if (this.props.isTimelineReleaseAuthorization) {
      confirmAlert({
        title: 'Consumer Privacy Act Info',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Opt-out',
            onClick: () => {
              this.uploadAuthorization(true)
            }
          },
          {
            label: "Don't opt-out",
            onClick: () => {
              this.uploadAuthorization(false);
            }
          },
          {
            label: 'Cancel',
            onClick: () => {}
          }
        ]
      });

    } else {
      await this.uploadAuthorization();
    }
  }

  render() {
    return (
      <div style={{height: '100vh', marginTop: 0}} id='signatureMain'>
        <img src={logo} alt="iBottt logo" style={{width: 50, height: 50, marginLeft: 20, marginTop: 20}}/>
        {this.state.transactionStatus === 'Pending Authorization' &&
          <div>
            {this.state.pdf && this.state.pdfUrl &&
              <div className='.layer0' style={{width: '100vw', marginLeft: 20, marginRight: 20, marginTop: -5, marginBottom: 10}}>
              <p>To sign with iBottt</p>
              <ol className='.layer0' style={{width: '100vw', marginLeft: 20, marginRight: 20, marginTop: -5, marginBottom: 10}}>
                <li>Scroll/zoom to the signature line.</li>
                <li>Click sign</li>
                <li>Draw your signature and click done</li>
              </ol>
              </div>}
            {
              this.state.pdf && this.state.pdfUrl &&
                <div id="pdfDocument" style={{height: '50vh', width: 650}}>
                  <div style={{position: "absolute", marginLeft: "-40px", marginTop: "25vh", zIndex: 900, color: 'red'}}>
                    <p style={{fontWeight: 'bold', width: 35, margin: 0, fontSize: 10, zIndex: 901}}>Align</p>
                    <h3 style={{fontWeight: "bolder", marginTop: "-16px"}}>→</h3>
                    <Button size='small' onClick={() => this.setState({isSigning: !this.state.isSigning})}
                            style={{position: "absolute", marginLeft: 0, marginTop: "-16px", zIndex: 900, color: 'black', backgroundColor: 'rgba(80, 158, 244, 0.7)', fontSize: '10px', minWidth: '40px'}}>{
                      this.state.isSigning ? "Scroll" : "Sign"}</Button>
                    <Button size='small' onClick={() => this.clearSignature()}
                            style={{position: "absolute", marginTop: "18px", marginLeft: 0, zIndex: 900, color: 'black', backgroundColor: 'rgba(80, 158, 244, 0.7)', fontSize: '10px', minWidth: '40px'}}>Clear
                    </Button>
                  </div>
                  <Document ref={(ref) => {
                    this.documentRef = ref
                  }} onLoadSuccess={this.onPdfLoad} file={this.state.pdfUrl} style={{width: '600'}} >
                    {
                      Array.from(new Array(this.state.numPages),
                        (el, index) =>
                          <div className='container_row' ref={(ref) => {this.pageRefs[index] = ref}}>
                            <Page className='layer2' pageNumber={index + 1} width={600} onLoadSuccess={() => {
                              if (index === this.state.numPages - 1) {
                                this.pageRefs[index].scrollIntoView({
                                  inline: 'start',
                                  block: 'start'
                                });
                                document.getElementById('signatureMain').scrollIntoView();
                              }
                            }}/>
                            <CanvasDraw
                              className='layer1'
                              onChange={() => this.signed = true}
                              hideGrid={true}
                              canvasWidth="100%"
                              canvasHeight="100%"
                              lazyRadius={0}
                              brushColor="#000000"
                              gridColor="rgba(255, 255, 255, 0)"
                              brushRadius={1}
                              disabled={!this.state.isSigning}
                              hideInterface={!this.state.isSigning}
                              style={{touchAction: this.state.isSigning ? "none" : "auto", zIndex: 5, background: "none", position: 'relative'}}
                              ref={(ref) => {
                                this.signatureRefs[index] = ref
                              }}/>
                          </div>
                      )
                    }
                  </Document>
                </div>
            }
            {!this.state.pdf &&
              <div>
              <p>By signing below, I certify that I agree to the terms in the transaction as attached.</p>
              <SignatureCanvas onBegin={() => this.signed = true} ref={(ref) => {this.sigCanvas = ref}}
              canvasProps={{height: 100, width: 500}} />
              <button onClick={() => this.clearSignature()}>Clear</button>
              </div>
            }
              <img src={signatureLine} alt="signature line" ref={(ref) => {this.imageCanvas = ref}} hidden />
            {this.state.submitSuccessful && <p className='margin-left'>Successfully authorized!</p>}
            {this.state.submitError && <p className='margin-left'>{this.state.submitError}</p>}
            <Box sx={{m: 1, position: 'relative'}} >
              <Button onClick={() => this.authorize()} disabled={this.state.isSubmitting} style={{marginLeft: 40, color: 'black', backgroundColor: 'rgba(80, 158, 244, 0.7)'}}>Done</Button>
              {this.state.isSubmitting && <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '30%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />}
            </Box>
          </div>
        }
        {this.state.transactionStatus && this.state.transactionStatus !== 'Pending Authorization' && this.state.transactionStatus !== 'Pending Payment' &&
          <div className='margin-left'>Thanks for signing</div>
        }
        {
          this.state.transactionStatus === 'Pending Payment' &&
          <div style={{marginLeft: 20, marginRight: 20}}>
            <p>Authorization successful! Please pay ${this.state.balance.toFixed(2)} below.</p>
            <NewPayment balance={this.state.balance} transactionId={this.transactionId}
                     type={this.type} successCallback={() => this.setState({transactionStatus: "In Process"})} />
          </div>
        }
        <br/>
        <br/>
      </div>
    )
  }
}

export default Signature;
