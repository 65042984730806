import React, { Component } from 'react';
import axios from 'axios';
import urlConstants from "../variables/urlConstants";
import {Redirect} from "react-router-dom";
import login from "./login.png";
import footerlogo from "./footer-logo.png";
// import queryString from 'query-string';
import qs from 'qs'


class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_confirmation: '',
      redirect: false
    };
    let queryParams = this.props.location.search;
    let parsed = qs.parse(queryParams);
    this.state.token = parsed.token;
    this.state.type = parsed.type;
    this.state.email = parsed.email;
    this.state.userId = parsed.userId;
    this.submitPasswordReset = this.submitPasswordReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  submitPasswordReset(e) {
    e.preventDefault();
    if (this.state.password.length < 8) {
      alert("Password is too short.");
      return;
    } else if (this.state.password !== this.state.password_confirmation) {
      alert("Your passwords do not match.");
      return;
    } else if (!this.state.token || !this.state.type || !this.state.userId || !this.state.email) {
      alert("Please follow the link on your email to reset the password.");
      return;
    }
    axios.post(urlConstants.BASE_URL + '/resetPassword', {
      password: this.state.password,
      userId: this.state.userId,
      token: this.state.token,
      type: this.state.type,
      email: this.state.email
    })
    .then(() => {
      alert('Password successfully reset.');
      this.setState({
        redirect: true
      });
    })
    .catch(err => {
      if (err && err.response.status >= 400 && err.response.status < 500) {
        alert("Your reset token may have expired. Please request a password reset again.");
      } else {
        alert("Please try again later");
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" push={false} />
    }
    return (

      <div className="container-fluid login_singup_wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="left_side">
                <img src={login}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_side_form singin">
                <div className="login_form">
                  <a href="/"><img src={footerlogo}/></a>
                  <h2>Reset Password Form</h2>
                  <form className="FormFields" onSubmit={this.submitPasswordReset}>
                    <div className="FormField">
                      <label className="FormField__Label" htmlFor="password">Enter New Password</label>
                      <input required pattern=".{8,}" type="password" id="password" className="FormField__Input" placeholder= "Enter Password - min 8 char w/ 1 number, 1 special char"
                             name="password" value={this.state.password} autoComplete='new-password' onChange={this.handleChange} />
                    </div>
                    <div className="FormField">
                      <label className="FormField__Label" htmlFor="password">Retype Password</label>
                      <input required type="password" id="password_confirmation" className="FormField__Input" placeholder= "Retype Password"
                             name="password_confirmation" value={this.state.password_confirmation} autoComplete='new-password' onChange={this.handleChange} />
                    </div>
                    <div className="FormField">
                      <button className="FormField__Button mt-4" type='submit' >Request Password Reset</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      
    );
  }
}

export default PasswordResetForm;
