import React, {Component} from 'react';
import logo from "../../layouts/applogo.png";
import Box from '@material-ui/core/Box';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import '../../layouts/App.css';
import members from "../../layouts/members.png";
import facility from "../../layouts/garage.png";
import insurance from "../../layouts/insurance2.png";
import logo3 from "../../layouts/iAuthoLogo.png";
import StartHereIcon from '../../assets/img/start_here_icon.png'
import AboutPaymentsPage from "../../layouts/AboutPaymentsPage";

import appstore from "../../layouts/appstore.png";
import playstore from "../../layouts/playstore.png";
import appstore1 from "../../assets/img/appstore1.png";
import playstore1 from "../../assets/img/playstore1.png";
import footerlogo from "../../layouts/footer-logo.png";
import sliderbanner from "../../assets/img/banner.png";
import sliderbanner1 from "../../layouts/innerbanner.png";
import sliderbanner2 from "../../layouts/innerbanner.png";

import chat from "../../assets/img/chat.png";

class PrivacyPolicy extends Component {
    constructor(props){
    super(props);

    this.state = {
      email: '',
      tabIdx: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('message');
    if (message) {
      alert(message);
    }
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleTabChange(e, newValue) {
    this.setState({
      tabIdx: newValue,
    })
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  popMenu(e){
    let menu = document.getElementById('navbarNavDropdown1')
    let menu1 = document.getElementById('navbarNavDropdown')

    if(menu.classList.contains('show') && menu1.classList.contains('show')){
      menu.classList.remove('show')
      menu1.classList.remove('show')
    }else{
      menu.classList.add('show')
      menu1.classList.add('show')
    }

  }

  render() {
    return (

      <div id='homeContainer' >
        <header className="header  navbar-fixed-top">
          <div className="container">
            <div className="row">
              <nav className="navbar navbar-expand-md">
                <a className="navbar-brand" href="#"><img src={footerlogo}/></a>
                <button className="navbar-toggler"  onClick={this.popMenu} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse  " id="navbarNavDropdown1">
                  <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}} className='about-us-margin'>
                      <Tabs value={this.state.tabIdx} onChange={this.handleTabChange}  className="navbar-nav mr-auto">
                        <a className="nav-link" href="/#/home">Home</a>
                        <a className="nav-link" href="/#/about-us">About Us</a>
                        <a className="nav-link" href="/#/about-payments">Payments</a>
                        
                      </Tabs>
                    </Box>
                  </div>
                  
                  
                </div>
              </nav>
            </div>
          </div>
        </header>

        <div className="banner">
          <img src={sliderbanner1}/>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner_caption">
                  <h2>Privacy <span>Statement</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container privacy-document">
          <div className="row">
            <div className="col-md-12">
              <h2>Effective January 1, 2020</h2>
              <p>This Privacy Statement is intended to help you understand what information about you iBottt collects (including on
                iBottt.com and iBottt mobile device applications intended for use by consumers (collectively, "iBottt web sites"))
                and how we use the information collected. By using iBottt web sites, you signify your consent to the data practices
                described in this Privacy Statement and agree that your visit and any dispute over privacy is subject to the Terms
                of Use. You should check this Privacy Statement frequently for any changes. If you have any questions or suggestions
                regarding our Privacy Statement, please email us at info@iBottt.com and (please include your name, mailing address
                and email address with your inquiry):<br/><a href="mailto:info@iBottt.com">info@iBottt.com</a><br/>
                Subject line: Privacy Agreement</p>

              <h2>Protecting the privacy of children</h2>
              <p>iBottt.com & iBottt moblie device application web sites are not directed at children and we will not knowingly allow
                children to provide any personally identifiable information. If you are a minor, please do not provide any
                information about yourself.</p>

              <ol>
                <li>Collected Information</li>
                <li>How we use the information we collect.</li>
                <li>How we protect your information</li>
                <li>How we disclosure information to third parties</li>
                <li>3rd party links</li>
                <li>How to update, change the information or opt out of marketing emails</li>
                <li>Updates to our Privacy Statement</li>
              </ol>

              <h1>State Specific Privacy Disclosures</h1>

              <h2>1. Collected Information (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>When you give information to iBottt</p>
              <p>iBottt will collect your name, mailing address, email address, and payment card information when you purchase
                iBottt® Vehicle Timeline Report ("iBottt Reports"). iBottt may require you to submit a Vehicle Identification Number
                (VIN) or license plate information to provide vehicle history information for the relevant vehicle. If you choose to
                use complementary services, iBottt may require you to provide an email address and password and create an account.
                If you submit a request for information on any iBottt web site, iBottt will collect any information you submit in
                connection with your request, including but not limited to, name, email address, telephone number, and any
                personalized message included in your request.</p>

              <p>iBottt may collect any information that you provide to iBottt (1) through the iBottt.com service, (2) in the process
                of sharing feedback related to third party products or services, and/or (3) through comments on public postings on
                iBottt mobile application devices or web sites. Please be aware that any such information may be viewable by others;
                please do not disclose any personal information or any information that you do not wish to make publicly
                available.</p>

              <p>If you permit iBottt to access your geolocation information through any iBottt web site, iBottt may collect (or have
                a third party collect) and store zip code level information. If you permit iBottt to access your geolocation through
                your mobile device, iBottt may use the maps application on your device and the provider of the application may have
                access to the zip code level information collected by iBottt. Your mobile device offers the ability to disable
                iBottt’s access to your geolocation information at any time.</p>

              <p>If you choose to participate in iBottt surveys while on iBottt web sites you may be asked to provide other anonymous
                demographic information such as zip code, age, gender, preferences and interests.</p>

              <h2>When you use iBottt web sites</h2>
              <p>iBottt may collect other information that you may submit in the course of searching for vehicles or creating an
                account such as zip code and/or type of vehicle viewed. If you call a toll-free telephone number provided on any
                iBottt web site, iBottt may record the call, as well as the date, time, and length of your call, and the phone
                number from which the call originated, along with other information that may be captured, and all such captured
                information may be used as described in this Privacy Statement.</p>

              <p>iBottt web sites may contain web beacons to collect data regarding user traffic patterns. iBottt web sites use
                cookies to collect information about your computer hardware (desktop, laptop, mobile devices and mobile device
                identifiers etc.) and software, such as referring domains, the type of browser used, operating system software,
                screen resolutions, color capabilities, IP addresses and plug-ins. We may collect, or have a third party collect on
                our behalf, data on how visitors use and navigate through the iBottt web sites, such as number of visits, paths
                taken, time spent on iBottt web sites, pages visited, vehicles researched and viewed and other similar information
                that will help us improve the quality of our service, create a better user experience and to serve targeted
                advertising. iBottt may use services such as Amazon Kinesis Data Analytics, Criteo SA, and/or Firebase Analytics
                provided by Google to collect such information. To learn more about how Firebase Analytics and Criteo SA collect and
                use data and to opt out, please visit their privacy pages at www.google.com/policies/privacy/partners/ and
                http://www.criteo.com/privacy/.  Criteo SA may use some data collected on the iBottt websites for cross device
                linking purposes. iBottt may include web beacons in some of our email communications to gauge the effectiveness of
                our customer communications and marketing campaigns.</p>

              <h2>When Received from a iBottt Customer</h2>
              <p>Automobile dealers and service shops may share your name, email address, and/or telephone number and VIN with iBottt
                for the purpose of providing additional services to you.</p>

              <p>Law enforcement agencies may provide police accident reports to iBottt for the purpose of fulfilling their
                obligation to make such reports available to their citizens and others.  Similarly, iBottt may receive personal
                information from insurers when such insurers’ search for a specific police accident report.</p>

              <h2>2. How we use the information we collect (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>iBottt uses the information you provide to deliver services requested by you. As more specifically explained in this
                Privacy Statement, if you submit a request for information on any iBottt web site, you agree that iBottt may share
                the information you submit with third parties, as necessary to respond to your request. Information related to your
                vehicle that you submit through iBottt may be included in the vehicle information compiled by iBottt for that
                vehicle. iBottt may use zip code level information to provide information regarding vehicles and dealerships in your
                then-current location. iBottt may also use any information you provide to send you additional communications about
                iBottt products and services and other special offers and promotions related to the used car buying and ownership
                process.</p>

              <p>We use information collected through cookies and web beacons to analyze usage, learn about users, and deliver
                customized content and advertising and uncover ways in which we can improve iBottt web sites and make them easier to
                use. Information provided by visitors to the iBottt web sites who choose to complete user surveys help us better
                understand how visitors to the iBottt web sites are using iBottt and develop better products and services for you.
                iBottt occasionally appends data collected through iBottt web sites with third party data to effectively plan
                customer communications and marketing campaigns and analyze how the iBottt web sites are used.</p>

              <p>iBottt may use your account login information to provide you with a better experience on the iBottt web sites across
                devices.</p>

              <p>If an automobile dealer or service shop provides your contact information and VIN to provide additional services to
                you, iBottt will use the information for such purposes.</p>

              <p>iBottt uses the police accident reports to provide such reports to citizens and others, as required by the relevant
                law enforcement agency and information submitted by insurers to provide such insurers with the requested police
                accident report.</p>

              <h2>3. How we protect your information (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>We maintain administrative, technical, and physical safeguards against unauthorized access to the information iBottt
                collects. For example, when you complete the (“Authorization via iBottt’s web site or mobile devices we will generate
                an (“Authorization Certificate”) with a secure afla numeric security coded certificate.  Report order form, all
                information you enter is encrypted via a secure sockets layer (SSL) before it is sent over the Internet to our secure
                servers and/or credit card processor, as applicable. We do not retain the full payment card account number submitted in
                the process of purchasing our systems, but we may store partial payment card numbers for up to six months for our
                reference. To protect against unauthorized access to your account, we recommend that you protect your password, log out
                of your account, and close your browser after each use.</p>

              <h2>4.  How we disclosure information to third parties (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>iBottt may disclose personal information about you: (1) to its service providers to provide the services requested
                by you; (2) to provide you with a better user experience; (3) as provided in this Privacy Statement; (4) to defend
                against legal claims or otherwise protect our rights; (5) as required by law, judicial proceeding, court order, or
                legal process served on iBottt; (6) for purposes of law enforcement investigations; and/or (7) as requested by you.
                If you submit a request for information from an automobile dealer or service shop through the iBottt web sites, you
                agree that iBottt may share the information you submit with such third parties, as necessary to respond to your
                request. Such third parties’ use or disclosure of the information you submit is subject to their privacy policies
                and practices and iBottt has no control over the subsequent use or disclosure of any information you provide. If
                iBottt offers functionality to share information via email or social media on any iBottt web site page and you
                choose to use such functionality, the information you provide may be shared with recipients. iBottt may share with
                third parties, including but not limited to, other users and vendors: (A) information you submit regarding your
                vehicle through iBottt mobile application devices; (B) your feedback regarding third party products or services; and
                (C) any other content or materials you submit through public posting on any iBottt web site. iBottt does not sell
                any information that specifically identifies you, such as your name, address, email address or telephone number,
                to any third party.</p>

              <p>We may disclose information collected through cookies and web beacons to our affiliates or to third parties, who
                may aggregate such online activity information across multiple web sites and may use such information for predictive
                advertising, measuring the effectiveness of advertising, cross device linking or for other purposes. To learn more
                or to make choices about their use of your information, please click here:
                <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.</p>

              <p>iBottt uses third party service providers to perform various functions such as processing credit card transactions,
                analyzing data, serving advertisements and providing marketing assistance.</p>

              <h2>5. 3rd party links (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>iBottt web sites and emails may include links to third party web sites and/or the opportunity to share on social
                media (such as the “Share” or “Like” features on Facebook). Your access to such third party web sites or use of
                social media features is subject to the privacy policies and practices of those third party web sites and/or social
                media providers. iBottt does not control the collection, use or disclosure of any information on such third party
                links. You may access iBottt web sites through links on third party web sites. By entering iBottt web sites through
                a third party web site you may be allowing such third party’s web site to view any information you provide to iBottt
                web site. If you are concerned about entering through a third party web site, you can access iBottt web sites
                directly at www.iBottt.com or by downloading the iBottt mobile device applications intended for use by consumers.
                iBottt is not responsible for the privacy practices or the content of any third party web sites. We encourage you to
                review the privacy policies of any web sites through which you access iBottt web sites or which you access through
                iBottt web sites or emails to better understand such third party web sites’ data collection and use practices.</p>

              <h2>6. How to update, change the information or opt out of marketing emails (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>You may update or change the information pertaining to your account by contacting iBottt by clicking here. You may
                opt out of receiving promotional emails from iBottt when you complete the iBottt Sign up form, or you may
                unsubscribe at any time by using the link included on all marketing emails. To unsubscribe from receiving
                promotional emails from iBottt you can unsubscribe via your account. If you choose to opt out of receiving
                promotional emails, you will only receive emails in connection with the iBottt services to which you have
                subscribed.</p>

              <p>To opt out of receiving targeted content and advertisements on iBottt web sites you can change your browser options
                to stop accepting cookies or to prompt you before accepting a cookie from any web site. You will be able to access
                and use iBottt web sites with cookies disabled, but the user experience may not be customized to your interests and
                needs.</p>

              <h2>7. Updates to our Privacy Statement (<a className="a-href" onClick={() =>     window.scrollTo(0, 0)}>Back to top</a>)</h2>
              <p>We reserve the right to modify this Privacy Statement at any time. Any changes to the Privacy Statement will be
                posted here. If the changes are significant, we will provide you additional, prominent notice as appropriate under
                the circumstances.</p>
            </div>
          </div>
        </div>
        
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer_logo"><img src={footerlogo}/></div>
              </div>
              <div className="col-md-12">
                <ul className="nav nav-justified align-items-center footer_nav">
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:info@ibottt.com">Contact Us: <span>info@ibottt.com</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:info@ibottt.com">Bug Reports: <span>info@ibottt.com</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/termsOfService">Terms of Services</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/privacyPolicy">Privacy Statement</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row d-flex align-items-center bottom_foot">
              <div className="col-md-6">
                <div className="store_links">
                  <ul className="nav">
                    <li className="nav-item">
                      <a className="nav-link" href="https://apps.apple.com/us/app/iautho/id1495116381"  target="_blank">
                        <img src={appstore}/>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <img src={playstore}/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="copyright_area">
                  <p>iBottt © 2024. All rights reserved
                  <a className="nav-link" href="mailto:techsupport@ibottt.com"  target="_blank">
                        <img src={chat}/>
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default PrivacyPolicy;

