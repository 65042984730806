import React, { Component } from "react";
import { Redirect } from "react-router";
import axios from 'axios';
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
// core components
import Button from "components/CustomButtons/Button.jsx";
import urlConstants from '../../variables/urlConstants';

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      navigate: false,
      profile: false,
      logout: false,
      type: this.props.type
    };
    if (this.props.type) {
      window.sessionStorage.setItem("type", this.props.type);
    } else {
      this.state.type = window.sessionStorage.getItem("type")
    }
  }
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleToggle2 = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleTouch = () => {
    localStorage.removeItem('user_name');
    this.setState({logout: true})
    
  };

  handleProfile = () => {
    this.setState({profile: true});
  };

  handleBilling = () => {
    this.setState({billing: true});
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    if (this.state.logout) {
      axios.post(urlConstants.BASE_URL + '/' + this.state.type + '/logout');
      return <Redirect to="/home" push={true} />
    }

    if (this.state.profile) {
      this.setState({
        profile: false
      });
      if (this.state.type === 'insurance') {
        return <Redirect to={"/insurance/admin/user"} push={true}/>
      }
      return <Redirect to={"/admin/user"} push={true}/>
    } else if (this.state.billing) {
      return <Redirect to={"/admin/billing"} push={true} />
    }
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className="agent_name">
        <div className="name_title">{ localStorage.getItem('user_name') }</div>
        <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Person"
            aria-owns={open ? "menu-list2-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle2}
            className={classes.buttonLink}
            id='personIcon'
          >
            <Person className={classes.icons} />

          </Button>
        <Poppers
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !open }) +
            " " +
            classes.pooperNav
          } >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list2-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={this.handleTouch}
                      className={classes.dropdownItem}
                      id="logout_button"
                    >
                      Logout
                    </MenuItem>
                    {this.props.type !== 'user' &&
                    <MenuItem
                      onClick={this.handleProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>}
                    {this.props.type !== 'user' &&
                      <MenuItem
                        onClick={this.handleBilling}
                        className={classes.dropdownItem}
                      >
                        Billing
                      </MenuItem>}
                    {this.props.type !== 'user' &&
                    <MenuItem
                      onClick={() => {
                        axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/stripeUrl')
                        .then(res => {
                          window.open(res.data, '_blank');
                        })
                        .catch(err => {
                          if (err && err.response && err.response.status === 403) {
                            alert('Please contact your manager to set up Stripe')
                          } else {
                            alert("Can't seem to access Stripe dashboard now. Please try again later");
                          }
                        })
                      }}
                      className={classes.dropdownItem}
                    >
                      Stripe Account
                    </MenuItem>}
                    <MenuItem
                      onClick={this.handleClose}
                      className={classes.dropdownItem}
                    >
                      Help
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
