import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import axios from 'axios';
import "./assets/css/material-dashboard-react.css?v=1.6.0";
import * as pdfjs from "pdfjs-dist";
// /css/material-dashboard-react.css?v=1.6.0

// core components
import Admin from "layouts/Admin.jsx";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import SignInForm from "layouts/SignInForm.jsx";
import SignUpForm from "layouts/SignUpForm.jsx";
import Home from "layouts/HomePage.jsx";

import ResetRequestForm from "./layouts/ResetRequestForm";
import PasswordResetForm from "./layouts/PasswordResetForm";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./views/AboutUs/AboutUs";
import AboutPayment from "./views/AboutPayment/AboutPayment";
import TermsOfService from "./views/TermsOfService/TermsOfService";
import Signature from "./views/Signature/Signature";
import NoInternetConnection from "./views/NoInternet/NoInternetConnection";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const hist = createBrowserHistory();
var called = 0;

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error && error.config && error.config.url && error.config.url.endsWith('/login')) {
    throw error;
  } else if (error && error.response && error.response.status === 401 && called === 0) {
    //place your reentry code
    called = 1;
    alert('Your session has timed out. Please login again.');
    called = 0;
    window.location.href = '#';
    window.location.reload();
  }
  throw error;
});

ReactDOM.render(
  <NoInternetConnection>
  <HashRouter history={hist}>
    <Switch>
      <Route path="/admin" render={(props) => <Admin {...props} type='facility' />} />
      <Route path="/dashboard" render={(props) => <DashboardPage {...props} />} />
      <Route path="/home" component={Home} />
      <Route path="/signIn" render={(props) => <SignInForm {...props} type='facility' />} />
      <Route path="/signUp" render={(props) => <SignUpForm {...props} type='facility' />} />

      <Route path="/insurance/admin" render={(props) => <Admin {...props} type='facility' />} />
      <Route path="/insurance/signIn" render={(props) => <SignInForm {...props} type='facility' skipRegister={true} />} />
      <Route path="/insurance/signUp" render={(props) => <SignUpForm {...props} type='facility' skipRegister={true} />} />

      <Route path="/user/admin" render={(props) => <Admin {...props} type='user' />} />
      <Route path="/user/signIn" render={(props) => <SignInForm {...props} type='user' />} />
      <Route path="/user/signUp" render={(props) => <SignUpForm {...props} type='user' />} />
      <Route path="/user/signature" render = {(props) => <Signature {...props} type='user' />} />

      <Route path="/user/forgotPassword" render={(props) => <ResetRequestForm {...props} type='user' />} />
      <Route path="/facility/forgotPassword" render={(props) => <ResetRequestForm {...props} type='facility' />} />
      <Route path="/facility/signature" render = {(props) => <Signature {...props} type='facility' />} />
      <Route path="/insurance/forgotPassword" render={(props) => <ResetRequestForm {...props} type='facility' />} />
      <Route path="/resetPassword" render={(props) => <PasswordResetForm {...props} />} />

      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/termsOfService" component={TermsOfService} />

      <Route path="/about-us" component={AboutUs} />
      <Route path="/about-payments" component={AboutPayment} />

      <Redirect from="/" to="/home" />


    </Switch>
  </HashRouter>
  </NoInternetConnection>,
  document.getElementById("root")
);
