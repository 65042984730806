import cardsAndWalletsImg from '../assets/img/cardsAndWallets.png';
import additionalPaymentsImg from '../assets/img/additionalPayments.png';
import networkTokensImg from '../assets/img/networkTokens.png';

const AboutPaymentsPage = () => {
    return (
        
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="about_payments">
                        <h3>Cards and wallets</h3>
                        <p>Integrated per-transaction pricing means no setup fees or monthly fees. The price is the same for all cards and digital wallets.</p>
                        <img src={cardsAndWalletsImg}/>
                        <h3>Bank debits and transfers</h3>
                        <p>Accept large payments or recurring charges securely with ACH debit, ACH credit, or wire transfers. </p>
                        <h3>Additional payment methods</h3>
                        <img src={additionalPaymentsImg}/>
                        <p>Increase conversion by supporting popular payment methods around the world with a single integration. </p>
                        <h3>3D Secure authentication</h3>
                        <p>3D Secure is an authentication method used to verify a customer's identity before an online card purchase.</p>
                        <h3>Card account updater</h3>
                        <p>Automatically update expired or renewed card information for saved customers.</p>
                        <h3>Adaptive Acceptance</h3>
                        <p>Machine learning models that help increase revenue for your business by improving authorization rates in real-time.</p>
                        <h3>Network tokens</h3>
                        <p className="last">Secure payment credentials that help increase revenue and improve the customer experience.</p>
                        <ul>
                            <li>Accept one-time and recurring payments</li>
                            <li>Built-in conversion optimizations</li>
                            <li>20+ payment methods, 30+ languages, 135+ currencies</li>
                            <li>Customize with your branding</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPaymentsPage;
