/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "../routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import logo from "../assets/img/word_logo.png";
import logoCircle from "./iauthocircle.png";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import urlConstants from "../variables/urlConstants";

/*dashboard highlight*/
class Dashboard extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      hasImage: true,
      fixedClasses: "dropdown show",
      // posts: [],
      mobileOpen: false
    };

    this.switchRoutes = (
      <Switch>
        {routes(this.props.type).map((prop, key) => {
          if (prop.layout === "/admin") {
            return (
              <Route
                path={prop.layout + prop.path}
                render={prop.render}
                key={key}
              />
            );
          } else if (prop.layout === '/insurance/admin') {
            return (
              <Route
                path={prop.layout + prop.path}
                render={prop.render}
                key={key}
              />
            );
          } else if (prop.layout === '/user/admin') {
            return (
              <Route
                path={prop.layout + prop.path}
                render={prop.render}
                key={key} />
            )
          }
        })}
      </Switch>
    );
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } 
    else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps ";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/emailVerified')
    .then(res => {
      if (!res.data) {
        confirmAlert({
          title: ' Verify your email',
          message: 'Please verify your email. Please refresh once you\'ve completed this step',
          buttons: [
            {
              label: 'Send email',
              onClick: () => {
                axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/sendVerifyEmail').finally(() => {
                  alert('Please check your email for the verification email');
                  this.setState({isSubmitted: true, error: false, valid: true})
                });
              }
            }
          ],
          closeOnClickOutside: false,
        });
      }
    })
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }


  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
            
        <Sidebar
          routes={routes(this.props.type).filter(elem => elem.path !== '/user')}
          logoText={""}
          logo={logo}
          image={logoCircle}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          showStripe={this.props.type !== '/user'}
          type={this.props.type}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Navbar
            type={this.props.type}
            routes={routes(this.props.type)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{this.switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{this.switchRoutes}</div>
          )}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
