import React, {useState, useEffect} from 'react';
import logo from '../../layouts/iauthocircle.png'

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(()=>{
    setOnline(navigator.onLine)
  },[])

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true)
  });

  window.addEventListener('offline', () => {
    setOnline(false)
  });

  // if user is online, return the child component else return a custom component
  if(isOnline){
    return(
      props.children
    )
  } else {
    return (
      <div style={{marginLeft: 20, marginTop: 20}}>
        <img src={logo} style={{width: 60, height: 60}} alt='ibottt logo' />
        <p>No Internet Connection. Please try again later or contact info@ibottt.com with any issues.</p>
      </div>)
  }
}

export default NoInternetConnection;
