import React, { Component } from 'react';
import {Button} from "@material-ui/core";
import axios from 'axios';
import CustomInput from "../../components/CustomInput/CustomInput";
import urlConstants from '../../variables/urlConstants';

import './VehicleInfoModal.css';
import GridItem from "../../components/Grid/GridItem";

class VehicleInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldVehicleInfo: this.props.vehicleInfo ? JSON.parse(JSON.stringify(this.props.vehicleInfo)) : {},
      vehicleInfo: this.props.extractedVehicleInfo ? JSON.parse(JSON.stringify(this.props.extractedVehicleInfo)) : {},
      isSubmitting: false
    }

    this.handleChange = this.handleChange.bind(this);
  }

  defaultString(actual, property, fallback) {
    if (actual && actual[property]) {
      return actual[property];
    }
    return fallback;
  }

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    let newVehicleInfo = this.state.vehicleInfo;
    newVehicleInfo[name] = value;

    this.setState({
      vehicleInfo: newVehicleInfo
    });
  }

  updateVinInfo() {
    if (!this.state.vehicleInfo.vin) {
      this.setState({
        errorMessage: "Please enter a VIN"
      });
      return;
    }
    this.setState({
      errorMessage: "",
      isSubmitting: true
    });

    let data = {
      vin: this.state.vehicleInfo.vin
    };
    if (this.state.vehicleInfo.licenseNum) {
      data.licenseNum = this.state.vehicleInfo.licenseNum;
    }
    if (this.state.vehicleInfo.details) {
      data.details = this.state.vehicleInfo.details;
    }
    if (this.state.vehicleInfo.miles) {
      data.miles = this.state.vehicleInfo.miles;
    }

    return axios.patch(urlConstants.BASE_URL + '/vehicle/vin', data)
    .then(() => {
      if (!data.licenseNum) {
        data.licenseNum = this.state.oldVehicleInfo.licenseNum;
      }
      if (!data.details) {
        data.details = this.state.oldVehicleInfo.details;
      }
      if (!data.miles) {
        data.miles = this.state.oldVehicleInfo.miles;
      }
      this.setState({
        errorMessage: "Successfully Updated!",
        oldVehicleInfo: data
      });
      if (!this.props.hideUpdate) {
        alert('Successfully updated vehicle info');
      }
      this.props.successCallback(data);
    })
    .catch(err => {
      if (err && err.response && err.response.status === 403) {
        this.setState({
          errorMessage: 'Once the license number is set, that value is not allowed to change. ' +
            'Please contact iBottt if you need to change these fields.'
        })
      } else {
        this.setState({
          errorMessage: "Please try again later"
        })
      }
    })
    .finally(() => {
      this.setState({
        isSubmitting: false
      })
    })
  }

  decodeVin() {
    let vin;
    if (this.state.oldVehicleInfo.vin) {
      vin = this.state.oldVehicleInfo.vin;
    } else {
      vin = this.state.vehicleInfo.vin;
    }
    axios.get(urlConstants.BASE_URL + '/vehicle/decodeVin?vin=' + vin)
    .then(res => {
      let newVehicleInfo = this.state.vehicleInfo;
      if (!this.state.oldVehicleInfo.licenseNum) {
        newVehicleInfo.licenseNum = res.data.licenseNum;
      }
      newVehicleInfo.details = res.data.vehicleDetails;
      this.setState({
        vehicleInfo: newVehicleInfo
      });
    })
  }

  render() {
    return (
      <div>
        <h3>Vehicle Details</h3>
        {Object.entries(this.state.oldVehicleInfo).length === 0
          && <p>Can't find VIN info in our database. You can update details below:</p>}
        <table className='vehicle-table'>
          <tbody>
          <tr>
            <th>Field</th>
            <th>Original Value</th>
            <th>Update Value</th>
          </tr>
          <tr>
            <td>VIN</td>
            <td>{this.defaultString(this.state.oldVehicleInfo, 'vin', "no VIN found")}</td>
            <td>{this.state.oldVehicleInfo.vin ? this.state.oldVehicleInfo.vin :
              <CustomInput
                labelText="VIN"
                inputProps={{
                  name:"vin",
                  value: this.state.vehicleInfo.vin,
                  onChange:this.handleChange
                }}
                formControlProps={{
                  fullWidth: true
                }} />
              }
              <Button onClick={() => this.decodeVin()} style={{marginLeft: '20px', marginTop: '-4px'}} >Decode Vin</Button>
            </td>
          </tr>
          <tr>
            <td>License #</td>
            <td>{this.defaultString(this.state.oldVehicleInfo, 'licenseNum', "no license # found")}</td>
            <td>
              <CustomInput
                labelText="License Number"
                inputProps={{
                  name:"licenseNum",
                  value: this.state.vehicleInfo.licenseNum,
                  onChange:this.handleChange
                }}
                formControlProps={{
                  fullWidth: true
                }} /></td>
          </tr>
          <tr>
            <td>Details</td>
            <td>{this.defaultString(this.state.oldVehicleInfo, 'details', "no vehicle details found")}</td>
            <td><CustomInput
              labelText="Details"
              inputProps={{
                name:"details",
                value: this.state.vehicleInfo.details,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>
          </tr>
          <tr>
            <td>Mileage</td>
            <td>{this.defaultString(this.state.oldVehicleInfo, 'miles', "no mileage found")}</td>
            <td><CustomInput
              labelText="Miles"
              inputProps={{
                name:"miles",
                value: this.state.vehicleInfo.miles,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>
          </tr>
          </tbody>
        </table>
        <p>Note: leaving fields blank will result in that field being unchanged</p>
        {this.state.errorMessage}
        {!this.props.hideUpdate &&
          <Button color="primary" onClick={() => this.updateVinInfo()} disabled={this.state.isSubmitting}>
          Update VIN info
          </Button>
        }
      </div>
    )
  }
}

export default VehicleInfoModal;
