import React, { Component } from 'react';
import CustomInput from "../../components/CustomInput/CustomInput";
import {Button} from "@material-ui/core";
import urlConstants from '../../variables/urlConstants';

import './ProfileModal.css';
import axios from "axios";

class ProfileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldProfileInfo: this.props.profileInfo ? JSON.parse(JSON.stringify(this.props.profileInfo)) : {},
      profileInfo: this.props.extractedProfileInfo ? JSON.parse(JSON.stringify(this.props.extractedProfileInfo)) : {},
      isSubmitting: false,
      signUpWarning: this.props.signUpWarning,
      city: "",
      state: "",
      zipCode: ""
    }

    this.handleChange = this.handleChange.bind(this);
  }

  defaultString(actual, property, fallback) {
    if (actual && actual[property]) {
      return actual[property];
    }
    return fallback;
  }

  addressString(actual, property, addressProperty, fallback) {
    if (actual && actual[property]) {
      let splitAddress = actual[property].split(', ');
      if (addressProperty === 'address') {
        if (splitAddress.length === 4) {
          return splitAddress[0] + ', ' + splitAddress[1];
        } else if (splitAddress.length > 0) {
          return splitAddress[0];
        }
      } else if (addressProperty === 'city') {
        if (splitAddress.length === 4) {
          return splitAddress[2];
        } else if (splitAddress.length > 1) {
          return splitAddress[1];
        }
      } else if (addressProperty === 'state') {
        let stateSplit;
        if (splitAddress.length === 4) {
          stateSplit = splitAddress[3].split(" ");
        } else if (splitAddress.length > 1) {
          stateSplit = splitAddress[2].split(" ");
        }
        if (stateSplit) {
          return stateSplit[0];
        }
      } else if (addressProperty === 'zipCode') {
        let zipSplit;
        if (splitAddress.length === 4) {
          zipSplit = splitAddress[3].split(" ");
        } else if (splitAddress.length > 1) {
          zipSplit = splitAddress[2].split(" ");
        }
        if (zipSplit && zipSplit.length > 1) {
          return zipSplit[1];
        }
      }
      return fallback;
    }
    return fallback;
  }

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    if (name === 'city' || name === 'zipCode' || name === 'state') {
      this.setState({
        [name]: value
      });
      return;
    }
    let newProfileInfo = this.state.profileInfo;
    newProfileInfo[name] = value;

    this.setState({
      profileInfo: newProfileInfo
    });
  }

  async updateUserInfo() {
    if (!this.state.profileInfo.email && !this.state.oldProfileInfo.email) {
      this.setState({
        errorMessage: "Please enter an email"
      });
      return;
    }
    this.setState({
      errorMessage: "",
      isSubmitting: true
    });

    let data = {
      email: this.state.profileInfo.email ? this.state.profileInfo.email : this.state.oldProfileInfo.email
    };
    try {
      let res = await axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupByEmail?type=user&email=' +
        encodeURIComponent(data.email));
      let newProfileInfo = Object.assign(this.state.profileInfo);
      newProfileInfo._id = res.data.userId;
      this.setState({
        profileInfo: newProfileInfo
      });
      if (!this.state.oldProfileInfo.email ||
        (this.state.profileInfo.email && this.state.profileInfo.email.trim() !== '' && this.state.profileInfo.email !== this.state.oldProfileInfo.email)) {
        if (this.props.vin) {
          try {
            await axios.patch(urlConstants.BASE_URL + '/vehicle/vin', {
              vin: this.props.vin,
              owner: res.data.userId
            });
          } catch(err) {
            if (err && err.response && err.response.status === 403) {
              this.setState({
                errorMessage: "Once the owner for this vehicle is set, you are not allowed to change it. " +
                  "Please contact iBottt if this needs to be changed.",
                isSubmitting: false
              });
              return;
            } else if (err && err.response && err.response.status >= 500) {
              this.setState({
                errorMessage: "There was an unknown error with updating the vehicle info",
                isSubmitting: false
              });
              return;
            } else {
              throw err;
            }
          }
        }
      }
    } catch(err) {
      let response = window.confirm("Can't find that email. Onboard user?");
      if (response) {
        try {
          let address = this.state.profileInfo.address + ", " + this.state.city + ", " + this.state.state + " " + this.state.zipCode;
          if (address.search(/^[, ]$/g) !== -1) {
            address = "";
          }
          await axios.post(urlConstants.BASE_URL + "/facility/addUserLink", {
            email: data.email,
            name: this.state.profileInfo.name ? this.state.profileInfo.name : this.state.oldProfileInfo.name,
            phoneNumber: this.state.profileInfo.phoneNumber ? this.state.profileInfo.phoneNumber : this.state.oldProfileInfo.phoneNumber,
            address: address ? address : this.state.oldProfileInfo.address
          });
          await this.updateUserInfo();
        } catch(err) {
          this.setState({
            errorMessage: "There was an unknown error with onboarding the user",
            isSubmitting: false
          });
          return;
        }
      }
      this.setState({
        errorMessage: "",
        isSubmitting: false
      });
      return;
    }

    if (this.state.profileInfo.address || this.state.city || this.state.state || this.state.zipCode) {
      let address = this.state.profileInfo.address + ", " + this.state.city + ", " + this.state.state + " " + this.state.zipCode;
      if (address.search(/^[, ]$/g) === -1) {
        data.address = address;
      }
    }

    if (this.state.profileInfo.phoneNumber) {
      data.phoneNumber = this.state.profileInfo.phoneNumber;
    }
    if (this.state.profileInfo.name) {
      data.name = this.state.profileInfo.name;
    }

    axios.patch(urlConstants.BASE_URL + '/' + this.props.type + '/updateUserInfo',
      Object.assign(data, { userId: this.state.profileInfo._id }))
    .then(() => {
      if (!data.address) {
        data.address = this.state.oldProfileInfo.address;
      }
      if (!data.name) {
        data.name = this.state.oldProfileInfo.name;
      }
      if (!data.phoneNumber) {
        data.phoneNumber = this.state.oldProfileInfo.phoneNumber;
      }
      this.setState({
        errorMessage: "Successfully Updated!",
        oldProfileInfo: data
      });
      if (!this.props.hideUpdate) {
        alert('Succesfully updated user info');
      }
      this.props.successCallback(data);
    })
    .catch(err => {
      this.setState({
        errorMessage: "Please try again later"
      })
    })
    .finally(() => {
      this.setState({
        isSubmitting: false
      })
    })
  }

  capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  render() {
    return (
      <div>
        <h3>{this.capitalize(this.props.targetGroup)} Details</h3>
        {this.state.signUpWarning &&
        <p>The user has been onboarded but has not signed up yet.</p>}
        {Object.entries(this.state.oldProfileInfo).length === 0
        && <p>Can't find the user info in our database. You can update details below:</p>}
        <table className='profile-table'>
          <tbody>
          <tr>
            <th>Field</th>
            <th>Original Value</th>
            {!this.props.viewOnly && <th>Update Value</th>}
          </tr>
          <tr>
            <td>Email</td>
            <td>{this.defaultString(this.state.oldProfileInfo, 'email', "no email found")}</td>
            {!this.props.viewOnly && <td>
              <CustomInput
                labelText="Email"
                inputProps={{
                  name:"email",
                  value: this.state.profileInfo.email,
                  onChange:this.handleChange
                }}
                formControlProps={{
                  fullWidth: true
                }} />
              </td>}
          </tr>
          <tr>
            <td>Name</td>
            <td>{this.defaultString(this.state.oldProfileInfo, 'name', "no name found")}</td>
            {!this.props.viewOnly && <td>
              <CustomInput
                labelText="Name"
                inputProps={{
                  name:"name",
                  value: this.state.profileInfo.name,
                  onChange:this.handleChange
                }}
                formControlProps={{
                  fullWidth: true
                }} /></td>}
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>{this.defaultString(this.state.oldProfileInfo, 'phoneNumber', "no phone # found")}</td>
            {!this.props.viewOnly && <td><CustomInput
              labelText="Phone Number"
              inputProps={{
                name:"phoneNumber",
                value: this.state.profileInfo.phoneNumber,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>}
          </tr>
          <tr>
            <td>Address</td>
            <td>{this.addressString(this.state.oldProfileInfo, 'address', "address","no address found")}</td>
            {!this.props.viewOnly && <td><CustomInput
              labelText="Street Address"
              inputProps={{
                name:"address",
                value: this.state.profileInfo.address,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>}
          </tr>
          <tr>
            <td>City</td>
            <td>{this.addressString(this.state.oldProfileInfo, 'address', "city", "no city found")}</td>
            {!this.props.viewOnly && <td><CustomInput
              labelText="City"
              inputProps={{
                name:"city",
                value: this.state.city,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>}
          </tr>
          <tr>
            <td>State</td>
            <td>{this.addressString(this.state.oldProfileInfo, 'address', "state","no state found")}</td>
            {!this.props.viewOnly && <td><CustomInput
              labelText="State"
              inputProps={{
                name:"state",
                value: this.state.state,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>}
          </tr>
          <tr>
            <td>Zip code</td>
            <td>{this.addressString(this.state.oldProfileInfo, 'address', "zipCode","no zip code found")}</td>
            {!this.props.viewOnly && <td><CustomInput
              labelText="Zip Code"
              inputProps={{
                name:"zipCode",
                value: this.state.zipCode,
                onChange:this.handleChange
              }}
              formControlProps={{
                fullWidth: true
              }} /></td>}
          </tr>
          </tbody>
        </table>
        {!this.props.viewOnly && <p>Note: leaving fields blank will result in that field being unchanged</p>}
        {this.state.errorMessage}
        {!this.props.viewOnly && !this.props.hideUpdate && <Button color="primary" onClick={() => this.updateUserInfo()} disabled={this.state.isSubmitting}>
          Update User info
        </Button>}
      </div>
    )
  }
}

export default ProfileModal;
