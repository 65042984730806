import React, {Component, useEffect, useState} from 'react';
import {Elements, PaymentElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { stripeKey } from '../../variables/stripeKeys';
import axios from "axios";
import urlConstants from "../../variables/urlConstants";
import {Button} from "@material-ui/core";
import {v4} from "uuid";

class NewPaymentCheckout extends Component {
  constructor(props) {
    super(props);
    this.clientSecret = null;
    this.state = {
      stripeAccount: null
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/paymentIntent', {
      transactionId: this.props.transactionId,
      idempotencyKey: this.idempotencyKey
    }).then(res => {
      this.clientSecret = res.data.clientSecret;
      this.setState({
        clientSecret: res.data.clientSecret
      });
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      errorMessage: 'Paying...',
      buttonDisabled: true,
    });

    const {stripe, elements} = this.props;

    console.error('Stripe')
    console.error(stripe);
    console.error(elements)
    if (!stripe || !elements) {
      return;
    }

    try {
      console.error(location.protocol + '//' + location.host + '/#/' + this.props.type + '/signIn?signature=true&transactionId=' + this.props.transactionId)
      let result;
      if (this.props.type === 'user') {
        const res = await axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/paymentIntent', {
          transactionId: this.props.transactionId,
        });
        result = await stripe.confirmPayment({
          elements,
          // confirmParams: {
          //   return_url: location.protocol + '//' + location.host + '/#/' + this.props.type + '/signIn?signature=true&transactionId=' + this.props.transactionId,
          // },
          redirect: 'if_required',
        });
      } else {
        result = await stripe.confirmPayment({
          elements,
          // confirmParams: {
          //   return_url: location.protocol + '//' + location.host + '/#/' + this.props.type + '/signIn?signature=true&transactionId=' + this.props.transactionId,
          // },
          redirect: 'if_required',
        });
      }
      if (result.error) {
        this.setState({
          errorMessage: result.error.message,
          buttonDisabled: false,
        });
        this.idempotencyKey = v4();
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          this.setState({
            errorMessage: 'Payment Succeeded!',
            buttonDisabled: false,
          });
        }
        this.props.successCallback();
      }
    } catch(err) {
      console.error(err);
      this.setState({
        errorMessage: 'Please try again later',
        buttonDisabled: false,
      });
      this.idempotencyKey = v4();
    }
  }

  render() {
    const options = {
      // passing the client secret obtained from the server
      clientSecret: this.clientSecret,
    };

    return (
      <div>
        {
          this.state.clientSecret &&
            <form onSubmit={this.handleSubmit}>
              <PaymentElement onChange={event => {
                if (event.value.type === 'apple_pay' || event.value.type === 'google_pay') {
                  this.payButton.click();
                }
              }}/>
              <Button type='submit' disabled={this.state.buttonDisabled} ref={(node) => {
                this.payButton = node;
              }}>Pay ${this.props.balance}</Button>
              {this.state.errorMessage}
            </form>
        }
      </div>
    )
  }
}

export default function NewPayment(props) {
  const stripePromise = loadStripe(stripeKey);
  const [ clientSecret, setClientSecret ] = useState('');
  const [warning, setWarning] = useState('');


  useEffect(() => {
    axios.post(urlConstants.BASE_URL + '/' + props.type + '/paymentIntent', {
      transactionId: props.transactionId,
    }).then(res => {
      setWarning('');
      setClientSecret(res.data.clientSecret);
    }).catch(err => {
      if (err && err.response && err.response.status === 400 && err.response.data.includes('not set up')) {
        setWarning('Warning: payment not set up');
      }
    });
  }, []);

  return (
    <div>
      {
        clientSecret &&
          <Elements stripe={stripePromise} options={{
            clientSecret,
          }}>
            <ElementsConsumer>
              {({stripe, elements}) => (
                <NewPaymentCheckout stripe={stripe} elements={elements} {...props} />
              )}
            </ElementsConsumer>
          </Elements>
      }
      {
        warning &&
        <p>Facility payments coming soon!</p>
      }
    </div>)
}
