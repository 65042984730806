import React from 'react';
import {LinearProgress} from "@material-ui/core";

// props (milliseconds, completion)
export default function LinearLoadingDeterminate(props) {
  const [progress, setProgress] = React.useState(0);
  const timeInterval = 250;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const newProgress = oldProgress + timeInterval / props.milliseconds * 100;
        return Math.min(newProgress, 95);
      });
    }, timeInterval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress variant="determinate" value={progress} />
  );
}
