import React, { Component } from 'react';
import urlConstants from "../../variables/urlConstants";
import LazyLoad from "react-lazyload";
import Button from "@material-ui/core/Button";
import members from "../../layouts/members.png";
import Modal from "react-modal";
import axios from 'axios';

import '../Typography/Typography.css';
import ReactToPrint from "react-to-print";
import Signature from "../Signature/Signature";
import Payment from "../Payment/Payment";
import NewTransaction from "../Transaction/NewTransaction";

class Archive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      modalComponent: null,
      modalOpen: false,
      posts: [],
      elements: [],
      nameList: [],
      emailList: [],
      companyInfoList: [],
      messages: [],
      messageLastEvaluatedKey: undefined,
      currentTransactionId: '',
      transactionPolling: undefined,
      facilityIconMap: new Map(),
      companyId: ""
    }
    this.refreshTransactions = this.refreshTransactions.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('#timelineContainer');
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/id')
    .then(res => {
      this.setState({
        companyId: res.data[this.props.type + 'Id']
      });
    });
    this.fetchAllTransactions()
    .then(res => {
      console.log(res);
      this.setState({
        posts: res.data.Items ? res.data.Items.slice(0, 10) : []
      });
      return res.data.Items;
    })
    .then(posts => {
      this.updateName(posts);
      this.updateCompanyName(posts);
    });

    this.setState({
      transactionPolling: setInterval(this.refreshTransactions, 30000)
    });


    window.onfocus = () => {
      this.setState({
        transactionPolling: setInterval(this.refreshTransactions, 30000)
      });
    };
    window.onblur = () => {
      clearInterval(this.state.transactionPolling);
    };

    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      clearInterval(this.state.transactionPolling);
      // Chrome requires returnValue to be set.
      return;
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.transactionPolling);
    window.onfocus = undefined;
    window.onblur = undefined;
  }

  fetchAllTransactions() {
    let outboundTransactions = [];
    let fetchTransactions = (url, isFirstTime, isOutbound, lastEvaluatedKey, stripeLastEvaluatedKey) => {
      let queryParams = '';
      if (!isFirstTime && !lastEvaluatedKey && !stripeLastEvaluatedKey) {
        return Promise.resolve();
      }
      if (lastEvaluatedKey) {
        queryParams = 'lastEvaluatedKey=' + encodeURIComponent(lastEvaluatedKey);
      }
      if (stripeLastEvaluatedKey) {
        if (queryParams) {
          queryParams += "&stripeLastEvaluatedKey=" + encodeURIComponent(stripeLastEvaluatedKey);
        } else {
          queryParams += "stripeLastEvaluatedKey=" + encodeURIComponent(stripeLastEvaluatedKey);
        }
      }
      return axios.get(url + queryParams)
      .then(res => {
        if (res.data.Items) {
          outboundTransactions = outboundTransactions.concat(res.data.Items);
        }
        return fetchTransactions(url, false, isOutbound, res.data.lastEvaluatedKey);
      });
    };

    return fetchTransactions(urlConstants.BASE_URL + '/' + this.props.type + '/companyTransactions', true, true,undefined)
    .then(() => {
      return {
        data: {
          Items: outboundTransactions
        }
      };
    })
  }

  getImage(companyId) {
    let newMap = this.state.facilityIconMap;
    if (!newMap.has(companyId)) {
      newMap.set(companyId, undefined);
      this.setState({
        facilityIconMap: newMap
      })
    }
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/facilityIcon?facilityId=' + encodeURIComponent(companyId))
    .then(res => {
      let newMap = this.state.facilityIconMap;
      newMap.set(companyId, res.data);
      this.setState({
        facilityIconMap: newMap
      })
    });
  }

  updateStatus(idx, newStatus) {
    let newState = this.state.posts;
    newState[idx].transactionStatus = newStatus;
    this.setState({
      posts: newState
    });
  }

  openSignatureModal(idx, transactionId, attachments, isTimelineReleaseAuthorization) {
    this.setState({
      modalOpen: true,
      modalComponent: <Signature successCallback={() => this.updateStatus(idx,
        this.state.posts[idx].balance > 0 ? "Pending Payment" : "In Process")}
                                 type={this.props.type} transactionId={transactionId}
                                 isTimelineReleaseAuthorization={isTimelineReleaseAuthorization}
                                 attachmentLinks={attachments} />
    })
  }

  openPaymentModal(idx, transactionId, balance) {
    this.setState({
      modalOpen: true,
      modalComponent: <Payment successCallback={() => this.updateStatus(idx, "In Process")}
                               type={this.props.type} transactionId={transactionId} balance={balance} />
    })
  }

  openReplyModal(vin, roNumber, email, targetGroup) {
    this.setState({
      modalOpen: true,
      modalComponent: <NewTransaction name='Customer' type={this.props.type} targetGroup={targetGroup}
                                      allowTypeSelection={false} roNumber={roNumber} vin={vin}
                                      userEmail={email} />
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      modalComponent: null
    })
  }

  declineTransaction(transactionId) {
    let confirm = window.confirm("Are you sure you want to decline the transaction?");
    if (confirm) {
      axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/decline', {
        transaction: transactionId
      })
      .then(res => {
        window.alert("Transaction declined");
      })
      .catch(err => {
        window.alert("Decline failed");
      })
    }
  }

  markDelivered(transactionId, idx) {
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/markDelivered', {
      transactionId: transactionId
    })
    .then(() => {
      let arr = this.state.posts.slice();
      arr[idx].transactionStatus = 'Delivered';
      this.setState({
        posts: arr
      });
    });
  }

  getAttachment(transactionId, filename) {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/files/' +
      encodeURIComponent(filename) + '?transactionId=' + encodeURIComponent(transactionId), {
      responseType: 'blob'
    })
    .then(res => {
      return res.data;
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  getServicePdf(transactionId) {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/servicePdf?transactionId=' + transactionId,
      {
        responseType: 'blob'
      })
    .then(res => {
      return res.data;
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', transactionId);

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    });
  }

  getMoreTransactions(lastEvaluatedKey) {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/companyTransactions' + '?lastEvaluatedKey=' + encodeURIComponent(lastEvaluatedKey))
    .then(res => {
      this.setState({
        posts: res.data.Items ? res.data.Items : [],
        lastEvaluatedKey: res.data.lastEvaluatedKey
      });
      return res.data.Items;
    })
    .then(posts => {
      this.updateName(this.state.posts + posts);
      this.updateCompanyName(this.state.posts + posts);
    });
  }

  getSignature(e, transactionId) {
    e.preventDefault();
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/signature?transaction=' + transactionId, {
      responseType: 'blob'
    })
    .then(res => {
      return res.data;
    }).then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', transactionId);

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    });
  }

  markInProcess(transactionId, idx) {
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/markInProcess', {
      transactionId: transactionId
    })
    .then(() => {
      let arr = this.state.posts.slice();
      arr[idx].transactionStatus = 'In Process';
      this.setState({
        posts: arr
      });
    });
  }

  updateName(posts) {
    let newNameList = [];
    let newEmailList = [];

    const promises = [];
    const idNameMap = new Map();
    const idEmailMap = new Map();

    for (let i = 0; i < posts.length; i++) {
      if (idNameMap.has(posts[i].userId)) {
      } else if (posts[i].userType) {
        idNameMap.set(posts[i].userId, undefined);
        idEmailMap.set(posts[i].userId, undefined);
        promises.push(
          axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
            posts[i].userId + '&type=' + (posts[i].initiatedBy !== 'user' ? posts[i].userType : posts[i].initiatedBy))
          .then(res => {
            if (res.data.Item) {
              idNameMap.set(posts[i].userId, res.data.Item.name);
              idEmailMap.set(posts[i].userId, res.data.Item.email);
            }
          })
        )
      } else {
        idNameMap.set(posts[i].userId, undefined);
        idEmailMap.set(posts[i].userId, undefined);
        for (let elem of ['user', 'facility', 'insurance']) {
          promises.push(
            axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
              posts[i].userId + '&type=' + elem)
            .then(res => {
              if (res.data.Item) {
                idNameMap.set(posts[i].userId, res.data.Item.name);
                idEmailMap.set(posts[i].userId, res.data.Item.email);
              }
            })
          );
        }
      }
    }

    Promise.allSettled(promises).then(() => {
      for (let i = 0; i < posts.length; i++) {
        newNameList[i] = idNameMap.get(posts[i].userId);
        newEmailList[i] = idEmailMap.get(posts[i].userId) ? idEmailMap.get(posts[i].userId) : "N/A";
      }
      this.setState({
        nameList: newNameList,
        emailList: newEmailList
      })
    });
  }

  updateCompanyName(posts) {
    let newCompanyNames = [];
    const idNameMap = new Map();

    let promises = [];
    for (let i = 0; i < posts.length; i++) {
      if (idNameMap.has(posts[i].companyInfo)) {
        continue;
      } else if (posts[i].initiatedBy) {
        idNameMap.set(posts[i].companyInfo, undefined);
        promises.push(
          axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
            posts[i].companyInfo + '&type=' + (posts[i].initiatedBy !== 'user' ? posts[i].initiatedBy : posts[i].userType))
          .then(res => {
            if (res.data.Item) {
              idNameMap.set(posts[i].companyInfo, res.data.Item.name + " <" + res.data.Item.email + ">");
            }
          })
        )
      } else {
        idNameMap.set(posts[i].companyInfo, undefined);
        for (let elem of ['user', 'facility', 'insurance']) {
          promises.push(
            axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupById?userId=' +
              posts[i].companyInfo + '&type=' + elem)
            .then(res => {
              if (res.data.Item) {
                idNameMap.set(posts[i].companyInfo, res.data.Item.name + " <" + res.data.Item.email + ">");
              }
            })
          );
        }
      }

      Promise.allSettled(promises).then(() => {
        for (let i = 0; i < posts.length; i++) {
          newCompanyNames[i] = idNameMap.get(posts[i].companyInfo);
        }
        this.setState({
          companyInfoList: newCompanyNames
        })
      })
    }
  }

  refreshTransactions() {
    this.fetchAllTransactions()
    .then(res => {
      this.setState({
        posts: res.data.Items ? res.data.Items : []
      });
      return res.data.Items;
    })
    .then(posts => {
      this.updateName(posts);
      this.updateCompanyName(posts);
    });
  }

  markClosed(transactionId, idx) {
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/transaction/markClosed', {
      transactionId: transactionId
    })
    .then(() => {
      let arr = this.state.posts.slice();
      arr[idx].transactionStatus = 'Closed';
      this.setState({
        posts: arr
      });
    });
  }

  render() {
    const { posts } = this.state;

    const timeList = posts && posts.length ? (
      posts.map(post => {
        return new Date(post.transactionTime).toLocaleDateString() + " "
          + new Date(post.transactionTime).toLocaleTimeString();
      })
    ) : (
      []
    );

    const signatureList = posts && posts.length ? (
      posts.map(post => {
        if (post.transactionStatus === 'Declined') {
          return { className: 'declined-transaction', message: 'Declined' };
        } else if (post.signature && post.transactionStatus === 'Pending Payment') {
          return { className: 'approved-transaction',
            message: <div><span>Pending payment, </span>
              <a href={urlConstants.BASE_URL + '/' + this.props.type + '/transaction/signature?transaction=' + post._id}>Download Authorization</a></div>}
        } else if (post.signature) {
          return { className: 'approved-transaction',
            message: <a href={urlConstants.BASE_URL + '/' + this.props.type + '/transaction/signature?transaction=' + post._id}>Download Authorization</a>}
        } else {
          return { className: 'pending-transaction', message: 'Pending authorization' };
        }
      })
    ) : (
      []
    );

    const vinList = posts && posts.length ? (
      posts.map(post => {
        return post && post.vin ? post.vin : "Vin not found"
      })
    ) : (
      []
    );

    const attachments = [];
    for (let i = 0; i < posts.length; i++) {
      let transAttachments;
      try {
        if (posts[i].serviceDescription) {
          transAttachments = JSON.parse(posts[i].serviceDescription);
        } else {
          transAttachments = [];
        }
      } catch (err) {
        transAttachments = [posts[i].serviceDescription];
      }
      for (let j = 0; j < transAttachments.length; j++) {
        let filename = transAttachments[j];
        transAttachments[j] = <li><a href={urlConstants.BASE_URL + '/' + this.props.type + '/transaction/files/' +
        encodeURIComponent(filename) + '?transactionId=' + encodeURIComponent(posts[i]._id)} target={'_blank'}>{filename}</a></li>
      }
      attachments[i] = transAttachments;
    }
    this.state.elements = [];

    for (let i=0; i<posts.length; i++) {
      if (!posts[i] || !posts[i].subjectLine || !posts[i].vin) {
        continue;
      }
      this.state.value = i;
      if (posts[i].isMessage) {
        this.state.elements.push(
          <div className={"timeline " + signatureList[i].className}>
            <div className="img">
              <img src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + posts[i]} alt="1" />
            </div>
            <div className="timeline-content">
              <div className="center_div">
                <div className="timeline-year">
                  {
                    (posts[i].initiatedBy === 'user' &&
                      <img src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + posts[i].vin}
                           alt="customer" style={{maxHeight: 75, maxWidth: 100}} />) ||
                    (posts[i].initiatedBy === 'facility' &&
                      <LazyLoad scrollContainer={'.overflowScroll'}>
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Facility" />
                      </LazyLoad>) ||
                    (posts[i].initiatedBy === 'insurance' &&
                      <LazyLoad>
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Insurance" />)
                      </LazyLoad>) ||
                    "Unknown"
                  }
                </div>
                <div className="description">
                <ul>
                  <li className="time"><b>Time:</b> {timeList[i]}</li>
                  {posts[i].initiatedBy === 'user' &&
                  <div>
                    <li><b>From</b>: {this.state.nameList[i]} {"<" + this.state.emailList[i] + ">"}</li>
                    <li><b>To</b>: {this.state.companyInfoList[i]} </li>
                  </div>}
                  {posts[i].initiatedBy !== 'user' &&
                  <div>
                    <li><b>From</b>: {this.state.companyInfoList[i]} </li>
                    <li><b>To</b>: {this.state.nameList[i]} {"<" + this.state.emailList[i] + ">"}</li>
                  </div>
                  }
                  <li><b>VIN</b>: {vinList[i]}</li>
                  <li><b>Attachments</b>:
                    <ul style={{marginTop: -1, marginBottom: -1}}>
                      {attachments[i].length === 0 && <li>No attachments available</li>}
                      {attachments[i].length > 0 && attachments[i]}
                    </ul>
                  </li>
                  <li><b>Message: <span className="blue-text">{posts[i].subjectLine}</span></b></li>
                  </ul>
                  <Button onClick={() => this.openReplyModal(posts[i].vin, posts[i].roNumber,
                    this.state.companyId === posts[i].companyInfo ? this.state.emailList[i] :
                      (this.state.companyInfoList[i] ? this.state.companyInfoList[i].split('<')[1].slice(0, this.state.companyInfoList[i].split('<')[1].length - 1) : ''),
                    this.state.companyId === posts[i].companyInfo ?
                      (posts[i].initiatedBy !== 'user' ? posts[i].userType : posts[i].initiatedBy)
                      : (posts[i].initiatedBy !== 'user' ? posts[i].initiatedBy : posts[i].userType))}>Reply</Button>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (posts[i].inbound) {
        this.state.elements.push(
          <div className={"timeline " + signatureList[i].className}>
            <div className="img">
              <img src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + posts[i].vin} alt="2" />
            </div>
            <div className="timeline-content">
              <div className="center_div">
                <div className="timeline-year">
                  {
                    (posts[i].initiatedBy === 'user' &&
                      <img src={members} className="timeline-icon" alt="Customer" />) ||
                    (posts[i].initiatedBy === 'facility' &&
                      <LazyLoad scrollContainer={'.overflowScroll'}>
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Facility" />
                      </LazyLoad>) ||
                    (posts[i].initiatedBy === 'insurance' &&
                      <LazyLoad>
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Insurance" />)
                      </LazyLoad>) ||
                    "Unknown"
                  }
                </div>
                <div className="description">
                <ul>
                  <li className="time"><b>Time:</b> {timeList[i]}</li>
                  <li><b>From</b>: {this.state.companyInfoList[i]} </li>
                  <li><b>To</b>: {this.state.nameList[i]} {"<" + this.state.emailList[i] + ">"}</li>
                  <li><b>Trans. ID</b>: {this.state.posts[i]._id}</li>
                  <li><b>VIN</b>: {posts[i].vin}</li>
                  <li><b>VIN</b>: <img src={posts[i].vehicleIcon} className="timeline-icon" alt="Insurance" /></li>
                  <li><b>Subject Line: {this.state.posts[i].subjectLine}</b></li>
                  {posts[i].transactionStatus === 'Pending Authorization' &&
                  <li><b>Authorization</b>: <button onClick={() => this.openSignatureModal(i, posts[i]._id, posts[i].serviceDescription, posts[i].isTimelineReleaseAuthorization)}>Sign here</button>
                    <button onClick={() => this.declineTransaction(posts[i]._id)}>Decline</button></li>}
                  {posts[i].transactionStatus === 'Pending Payment' &&
                  <li><b>Payment</b>: <button onClick={() => this.openPaymentModal(i, posts[i]._id, posts[i].balance)}>Make payment</button></li>}
                  {posts[i].transactionStatus !== 'Pending Authorization' &&
                  <li><b>Authorization</b>: {signatureList[i].message}</li>}
                  <li><b>Attachments</b>:
                    <ul style={{marginTop: -1, marginBottom: -1}}>
                      {attachments[i].length === 0 && <li>No attachments available</li>}
                      {attachments[i] && attachments[i]}
                    </ul>
                  </li>
                  </ul>
                </div>
                <Button onClick={() => this.openReplyModal(posts[i].vin, posts[i].roNumber,
                  this.state.companyId === posts[i].companyInfo ? this.state.emailList[i] : this.state.companyInfoList[i].split('<')[1].slice(0, this.state.companyInfoList[i].split('<')[1].length - 1),
                  this.state.companyId === posts[i].companyInfo ? posts[i].userType : posts[i].initiatedBy)}>Reply</Button>
              </div>
            </div>
          </div>
        );
      } else {
        this.state.elements.push(
          <div className={"timeline " + signatureList[i].className}>
            <div className="img">
              <img src={urlConstants.BASE_URL + '/vehicle/vehicleIcon?vin=' + posts[i].vin} alt="3" />
            </div>
            <div className="timeline-content">
              <div className="center_div">
                <div className="timeline-year">
                  {
                    (posts[i].initiatedBy === 'user' &&
                      <img src={members} className="timeline-icon" alt="Customer" />) ||
                    (posts[i].initiatedBy === 'facility' &&
                      <LazyLoad >
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Facility" />
                      </LazyLoad>) ||
                    (posts[i].initiatedBy === 'insurance' &&
                      <LazyLoad>
                        <img src={this.state.facilityIconMap.has(posts[i].companyInfo) ?
                          this.state.facilityIconMap.get(posts[i].companyInfo) :
                          this.getImage(posts[i].companyInfo)} className="timeline-icon" alt="Insurance" />)
                      </LazyLoad>) ||
                    "Unknown"
                  }
                </div>
                <p className="time"><p><b>Time:</b></p> {timeList[i]}</p>
                <div className="description">
                  <ul>
                  <li><b>From</b>: {this.state.companyInfoList[i]} </li>
                  <li><b>To</b>: {this.state.nameList[i]} {"<" + this.state.emailList[i] + ">"}</li>
                  <li><b>Trans. ID</b>: {this.state.posts[i]._id}</li>
                  <li><b>VIN</b>: {vinList[i]}</li>
                  
                  <li><b>Subject Line</b>: {posts[i].subjectLine}</li>
                  <li><b>Authorization</b>: {signatureList[i].message}</li>
                  <li><b>Attachments</b>:
                    <ul style={{marginTop: -1, marginBottom: -1}}>
                      {attachments[i].length === 0 && <li>No attachments available</li>}
                      {attachments[i] && attachments[i]}
                    </ul>
                  </li>
                  </ul>
                  {this.props.type !== 'user' && this.state.companyId === posts[i].companyInfo &&
                  <div>
                    {
                      (posts[i].transactionStatus === 'In Process' || posts[i].transactionStatus === 'Closed') &&
                      <li>
                        <button onClick={() => this.markDelivered(posts[i]._id, i)}>Mark as Delivered</button>
                      </li>
                    }
                    {
                      posts[i].transactionStatus === 'Delivered' &&
                      <li>
                        <button onClick={() => this.markInProcess(posts[i]._id, i)}>Mark as In Process</button>
                      </li>
                    }
                    {
                      posts[i].transactionStatus === 'Delivered' &&
                      <li>
                        <button onClick={() => this.markClosed(posts[i]._id, i)}>Mark as Closed</button>
                      </li>
                    }
                    
                  </div>

                  }
                </div>
                <Button onClick={() => this.openReplyModal(posts[i].vin, posts[i].roNumber,
                  this.state.companyId === posts[i].companyInfo ? this.state.emailList[i] :
                    (this.state.companyInfoList[i] ? this.state.companyInfoList[i].split('<')[1].slice(0, this.state.companyInfoList[i].split('<')[1].length - 1) : ''),
                  this.state.companyId === posts[i].companyInfo ?
                    (posts[i].initiatedBy !== 'user' ? posts[i].userType : posts[i].initiatedBy)
                    : (posts[i].initiatedBy !== 'user' ? posts[i].initiatedBy : posts[i].userType))}>Reply</Button>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div id="timelineContainer">
        <ReactToPrint style
                      trigger={() => <button className="print_btn">Print timeline...</button>}
                      content={() => this.printRef}/>
        <Modal
          isOpen={this.state.modalOpen}
          style={{
            overlay: {
              zIndex: '2000'
            }
          }}>
          <button onClick={() => this.closeModal()}>Close</button>
          {this.state.modalComponent}
        </Modal>
        <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-timeline overflowScroll" ref={(ref) => this.printRef = ref}>
                  {this.state.elements}
                </div>
              </div>
            </div>
        </div>
        {this.state.lastEvaluatedKey && <button onClick={() => this.getMoreTransactions(this.state.lastEvaluatedKey)}>See more</button>}
      </div>
    );
  }
}

export default Archive;