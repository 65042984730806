// https://stackoverflow.com/questions/17424639/extract-text-from-pdf-file-using-javascript
import pdfjs from "pdfjs-dist/build/pdf";
import axios from "axios";
import {BASE_URL} from "../variables/urlConstants";

async function extractFields(fileObj) {
  const formData = new FormData();
  formData.append('file', fileObj);
  const uploadResponse = await axios.post(BASE_URL + '/pdfAi/upload', formData, {
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
  const extractionResponse = await axios.post(BASE_URL + '/pdfAi/invoice', {
    docId: uploadResponse.data.docId,
  });
  return extractionResponse.data;
  // For testing only
  // return { "vin": "WP0CA2A82ES120551", "netCost": 6322.49, "vehicleDetails": "2014 PORS Boxster 2D CNVT 6-2.7L Gasoline Gasoline Direct Injection", "ownerFirstName": "Waqas", "ownerLastName": "Khalid", "licenseNum": "DRK M8R", "miles": 34829, "customerPhoneNum": "(650) 593-1019", "customerAddress": "956 Bransten San Carlos, CA 94070", "customerEmail": "", "roNumber": "313512" }
}

export default {
  extractFields: extractFields,
};
