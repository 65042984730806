import React, {Component} from 'react';
import {Select} from "@material-ui/core";
import axios from "axios";
import urlConstants from "../../variables/urlConstants";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SvgIcon from "@material-ui/core/SvgIcon";

function CustomSvgIcon(props) {
  return (
      <SvgIcon viewBox="0 0 30 30" width={30} height={30}>
          <path d="M29.2776 5.25624C28.8102 4.79369 28.2578 4.58344 27.6629 4.58344H25.3683V2.8594C25.3683 2.06045 25.0708 1.38765 24.5184 0.840999C23.966 0.294349 23.2861 0 22.4788 0H21.3314C20.5241 0 19.8442 0.294349 19.2918 0.840999C18.7394 1.38765 18.4419 2.06045 18.4419 2.8594V4.58344H11.5156V2.8594C11.5156 2.06045 11.2181 1.38765 10.6657 0.840999C10.1133 0.294349 9.43343 0 8.62606 0H7.47875C6.67138 0 5.9915 0.294349 5.43909 0.840999C4.88668 1.38765 4.58923 2.06045 4.58923 2.8594V4.58344H2.29462C1.65722 4.58344 1.1473 4.79369 0.679877 5.25624C0.212454 5.71879 0 6.22338 0 6.85413V29.7293C0 30.3601 0.212454 30.8646 0.679877 31.3272C1.1473 31.7897 1.65722 32 2.29462 32H27.7054C28.3428 32 28.8527 31.7897 29.3201 31.3272C29.7875 30.8646 30 30.318 30 29.7293V6.85413C29.9575 6.26543 29.7451 5.71879 29.2776 5.25624ZM20.7365 2.90144H23.0312V6.93824H20.7365V2.90144ZM6.88385 2.8594H9.17846V6.89619H6.88385V2.8594ZM7.43626 29.7293H2.25212V24.5992H7.43626V29.7293ZM7.43626 23.4639H2.25212V17.7451H7.43626V23.4639ZM7.43626 16.6097H2.25212V11.4796H7.43626V16.6097ZM14.3626 29.7293H8.58356V24.5992H14.3626V29.7293ZM14.3626 23.4639H8.58356V17.7451H14.3626V23.4639ZM14.3626 16.6097H8.58356V11.4796H14.3626V16.6097ZM21.2889 29.7293H15.5099V24.5992H21.2889V29.7293ZM21.2889 23.4639H15.5099V17.7451H21.2889V23.4639ZM21.2889 16.6097H15.5099V11.4796H21.2889V16.6097ZM27.6629 29.7293H22.4788V24.5992H27.6629V29.7293ZM27.6629 23.4639H22.4788V17.7451H27.6629V23.4639ZM27.6629 16.6097H22.4788V11.4796H27.6629V16.6097Z" fill="#0F8FE5"/>
      </SvgIcon>
  );
}

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformPrice: '?',
      authorizationsCount: '?',
      authorizationsUnitPrice: '?',
      videosUnitPrice: '?',
      videosCount: '?',
      freeAuthorizations: '?',
      freeVideos: '?',
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    }

    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.monthOptions = [ ...Array(12) ].map((_, i) => {
      let month = i + 1;
      return <MenuItem value={month}>{month}</MenuItem>;
    });
    this.yearOptions = [ ...Array(25) ].map((_ , i) => {
      let year = i + 2024;
      return <MenuItem value={year}>{year}</MenuItem>;
    });

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/usersList')
      .then(res => {
        this.setState({
          usersList: res.data.map(user => {
            return <li>{user.name}: {user.email}</li>
          })
        });
      });

    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/pricing')
      .then(res => {
        this.setState({
          platformPrice: res.data.platformFee,
          authorizationsUnitPrice: res.data.authorizationsUnitPrice,
          freeAuthorizations: res.data.freeAuthorizations,
          videosUnitPrice: res.data.videosUnitPrice,
          freeVideos: res.data.freeVideos,
        })
      })

    this.getUsage();
  }

  formatOrQuestionMark(value) {
    if (this.isNumber(value)) {
      return this.formatter.format(value);
    }
    return '?';
  }

  isNumber(value) {
    return typeof value === 'number';
  }

  handleDateChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
    this.getUsage();
  }

  getUsage() {
    this.setState({
      authorizationsCount: '?',
      videosCount: '?',
    })
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + `/billingUsage?month=${this.state.month}&year=${this.state.year}`)
      .then(res => {
        this.setState({
          authorizationsCount: res.data.authorizationsCount,
          videosCount: res.data.videosCount,
        });
      })
  }


  redirectToStripe() {

  }

  render() {
    const authorizationsPrice = this.isNumber(this.state.authorizationsCount) && this.isNumber(this.state.authorizationsUnitPrice) && this.isNumber(this.state.freeAuthorizations) ?
      Math.max(this.state.authorizationsCount - this.state.freeAuthorizations, 0) * this.state.authorizationsUnitPrice
      : '?';
    const videosPrice = this.isNumber(this.state.videosCount) && this.isNumber(this.state.videosUnitPrice) && this.isNumber(this.state.freeVideos) ?
      Math.max(this.state.videosCount - this.state.freeVideos, 0) * this.state.videosUnitPrice
      : '?';
    return (
      <div className='billing_wrapper'>
        {/* <h2>Billing</h2> */}
        <h4>Charges</h4>
        <div style={{float: 'left', marginLeft: '8px', marginRight: '8px'}}>
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            id="month-select"
            value={this.state.month}
            label="Month"
            name="month"
            onChange={this.handleDateChange}
            IconComponent={CustomSvgIcon}
          >
            {this.monthOptions}
          </Select>
        </div>
        <div style={{float: 'left', marginLeft: '8px', marginRight: '8px'}}>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            labelId="year-label"
            id="year-select"
            value={this.state.year}
            label="Year"
            name="year"
            onChange={this.handleDateChange}
            IconComponent={CustomSvgIcon}
          >
            {this.yearOptions}
          </Select>
        </div>
        <ol  style={{width: '100%', float: 'left'}} className='billing_listing'>
          <li>Monthly platform fee: {this.formatOrQuestionMark(this.state.platformPrice)}</li>
          <li>Authorizations ({this.formatOrQuestionMark(this.state.authorizationsUnitPrice)} each after {this.state.freeAuthorizations}): {this.state.authorizationsCount} = {this.formatOrQuestionMark(authorizationsPrice)}</li>
          <li>Videos ({this.formatOrQuestionMark(this.state.videosUnitPrice)} each): {this.state.videosCount} = {this.formatOrQuestionMark(videosPrice)}</li>
          <li>Total: {this.formatOrQuestionMark(this.state.platformPrice + authorizationsPrice + videosPrice)}</li>
        </ol>
        {/*<button onClick={() => this.redirectToStripe()}>Subscribe to Stripe/Manage payments</button>*/}
        <h4>Current Users</h4>
        <ol style={{width: '100%', float: 'left'}}>
          {this.state.usersList}
        </ol>
      </div>
    );
  }
}

export default Billing;
