import axios from 'axios';
import React, { Component } from 'react';
import ReactTable from "react-table-6";
import urlConstants from "../../variables/urlConstants";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "@material-ui/core/Button";

class LinkedUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errMessage: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/notifiedUsers?vin=' + this.props.vin)
    .then(res => {
      this.setState({
        data: res.data
      })
    });
  }

  signOut(id) {
    axios.delete(urlConstants.BASE_URL + '/' + this.props.type + '/notifiedUsers', {
      data: {
        companyId: id,
        vin: this.props.vin
      }
    })
    .then(() => {
      this.setState({
        data: this.state.data.filter(elem => elem._id !== id)
      })
    });
  }

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  signIn() {
    this.setState({
      errMessage: ''
    })
    let email = this.state.email;
    let userId;
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupByEmail?type=unknown&email=' + email)
    .then(res => {
      userId = res.data.userId;
      axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/notifiedUsers', {
        vin: this.props.vin,
        companyId: res.data.userId
      })
      .then(() => {
        this.setState({
          data: this.state.data.concat({
            email: email,
            _id: userId
          })
        })
      })
    })
    .catch(err => {
      if (err && err.response && err.response.status === 404) {
        axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/notifiedUsers', {
          vin: this.props.vin,
          companyId: email
        })
        .then(() => {
          this.setState({
            data: this.state.data.concat({
              email: email,
              _id: userId
            })
          })
        })
        .catch(() => {
          this.setState({
            errMessage: 'Please try again later'
          });
        })
      } else if (err && err.response && err.response.status >= 400 && err.response.status < 500) {
        this.setState({
          errMessage: 'Email not found'
        });
      } else {
        this.setState({
          errMessage: 'Please try again later'
        });
      }
    });
  }

  render() {
    let columns = [{
      id: 'email',
      Header: 'Email',
      accessor: d => d.email
    }];
    return (
      <div>
        <CustomInput
          inputProps={{
            inputProps: {
              "placeholder": "Email to sign in"
            },
            name: "email",
            value: this.state.email,
            onChange: this.handleChange
          }}
          formControlProps={{}}
        />
        <Button onClick={() => this.signIn()}>Sign in email</Button>
        {this.state.errMessage}
        <ReactTable columns={columns} data={this.state.data} defaultPageSize={10} />
      </div>
    )
  }
}

export default LinkedUsersModal;
