import React, {Component} from 'react';
import logo from "../../layouts/applogo.png";
import Box from '@material-ui/core/Box';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import '../../layouts/App.css';
import members from "../../layouts/members.png";
import facility from "../../layouts/garage.png";
import insurance from "../../layouts/insurance2.png";
import logo3 from "../../layouts/iAuthoLogo.png";
import AboutPage from "../../layouts/AboutPage";
import StartHereIcon from '../../assets/img/start_here_icon.png'
import AboutPaymentsPage from "../../layouts/AboutPaymentsPage";

import appstore from "../../layouts/appstore.png";
import playstore from "../../layouts/playstore.png";
import appstore1 from "../../assets/img/appstore1.png";
import playstore1 from "../../assets/img/playstore1.png";
import footerlogo from "../../layouts/footer-logo.png";
import sliderbanner from "../../assets/img/banner.png";
import sliderbanner1 from "../../layouts/innerbanner.png";
import sliderbanner2 from "../../layouts/innerbanner.png";

import chat from "../../assets/img/chat.png";



class AboutUs extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: '',
      tabIdx: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('message');
    if (message) {
      alert(message);
    }
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleTabChange(e, newValue) {
    this.setState({
      tabIdx: newValue,
    })
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  popMenu(e){
    let menu = document.getElementById('navbarNavDropdown1')
    let menu1 = document.getElementById('navbarNavDropdown')

    if(menu.classList.contains('show') && menu1.classList.contains('show')){
      menu.classList.remove('show')
      menu1.classList.remove('show')
    }else{
      menu.classList.add('show')
      menu1.classList.add('show')
    }

  }

  render() {
    return (

      <div id='homeContainer' >
        <header className="header  navbar-fixed-top">
          <div className="container">
            <div className="row">
              <nav className="navbar navbar-expand-md">
                <a className="navbar-brand" href="#"><img src={footerlogo}/></a>
                <button className="navbar-toggler" onClick={this.popMenu} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse  " id="navbarNavDropdown1">
                  <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}} className='about-us-margin'>
                      <Tabs value={this.state.tabIdx} onChange={this.handleTabChange}  className="navbar-nav mr-auto">
                        <a className="nav-link" href="/#/home">Home</a>
                        <a className="nav-link" href="/#/about-us">About Us</a>
                        <a className="nav-link" href="/#/about-payments">Payments</a>
                        
                      </Tabs>
                    </Box>
                  </div>
                  
                  
                </div>
              </nav>
            </div>
          </div>
        </header>

        
        <div className="banner">
          <img src={sliderbanner2}/>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner_caption">
                  <h2>About <span>Us</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
          
          
        
        <AboutPage />
        
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer_logo"><img src={footerlogo}/></div>
              </div>
              <div className="col-md-12">
                <ul className="nav nav-justified align-items-center footer_nav">
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:info@ibottt.com">Contact Us: <span>info@ibottt.com</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:info@ibottt.com">Bug Reports: <span>info@ibottt.com</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/termsOfService">Terms of Services</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/privacyPolicy">Privacy Statement</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row d-flex align-items-center bottom_foot">
              <div className="col-md-6">
                <div className="store_links">
                  <ul className="nav">
                    <li className="nav-item">
                      <a className="nav-link" href="https://apps.apple.com/us/app/iautho/id1495116381"  target="_blank">
                        <img src={appstore}/>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <img src={playstore}/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="copyright_area">
                  <p>iBottt © 2024. All rights reserved
                  <a className="nav-link" href="mailto:techsupport@ibottt.com"  target="_blank">
                        <img src={chat}/>
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default AboutUs;
