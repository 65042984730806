function isSubarrayEqual(arr1, idx, arr2) {
  for (let i = 0; i < arr2.length; i++) {
    if (arr1.length <= idx + i || !arr1[idx + i].startsWith(arr2[i])) {
      return false;
    }
  }
  return true;
}

// returns name, address, and phone number
function getCustomerInfo(pdfText) {
  let customerInfo = {};
  let text = pdfText.split('\n');
  let i = 0;
  while (i < text.length && !text[i].startsWith("Customer")) {
    i++;
  }
  if (i >= text.length) {
    return {};
  }

  i++;
  customerInfo['name'] = text[i].split('\t')[0];
  i++;
  try {
    if (text[i + 2].split('\t')[0].search(/(.*\d.*){10,}/) !== -1) {
      customerInfo['address'] = text[i].split('\t')[0] + ', ' + text[i + 1].split('\t')[0];
      customerInfo['phoneNumber'] = text[i + 2].split('\t')[0];
    } else {
      customerInfo['address'] = text[i].split('\t')[0] + ', ' + text[i + 1].split('\t')[0] +
        ', ' + text[i + 2].split('\t')[0];
      customerInfo['phoneNumber'] = text[i + 3].split('\t')[0];
    }
    return customerInfo;
  } catch(err) {
  }
}

export default {
  getCustomerInfo: getCustomerInfo,
};
