import React, {Component} from 'react';
import { Redirect } from "react-router";
import { NavLink } from 'react-router-dom';
import {confirmAlert, ConfirmAlert} from 'react-confirm-alert';

import './App.css';
import logo3 from "./iAuthoLogo.png";
import login from "./login.png";
import footerlogo from "./footer-logo.png";

import axios from 'axios'

import urlConstants from '../variables/urlConstants';
// import * as queryString from "querystring";
import qs from 'qs'

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";

class SignInForm extends Component{
    constructor(props) {
      super(props);
      this.state = {
        email:'',
        password:'',
        isSubmitted: false,
        error: false,
        isAuthenticated: false,
        invalid: false,
        valid: false,
        routePrepend: ''
      };

      if (props.skipRegister) {
        this.state.routePrepend = '/insurance';
      } else if (props.type === 'user') {
        this.state.routePrepend = '/user';
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    state = {
      open: false
    };

    componentDidMount() {
      let queryParams = qs.parse(this.props.location.search);
      if (queryParams.u) {
        axios.post(urlConstants.BASE_URL + "/user/login", {
          email: atob(queryParams.u),
          password: queryParams.m,
        })
        .then(res => {
          // if (this.props.type !== 'user' && res.data.stripeUrl) {
          //   let r = window.confirm("We detected that you don't have a Stripe account. You can use the app for authorization without an account, but will need one for payments. Sign up for Stripe before continuing?");
          //   if (r) {
          //     window.location.href = res.data.stripeUrl;
          //   }
          // }
          this.setState({isSubmitted: true, error: false, valid: true, routePrepend: '/user'});
        })
          .catch(err => {
            axios.post(urlConstants.BASE_URL + "/facility/login", {
              email: atob(queryParams.u),
              password: queryParams.m,
            })
              .then(res => {
                this.setState({isSubmitted: true, error: false, valid: true, routePrepend: '/facility'});
              })
          })
      }
    }

  handleToggle = () => {
      this.setState(state => ({ open: !state.open }));
    };

    userHasAuthenticated = authenticated => {
      this.setState({ isAuthenticated: authenticated });
    };

    handleChange(e) {
        let target = e.target;
        let value = target.value;
        let name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit(e) {
      e.preventDefault();
      this.setState({
        error: false,
        submitting: true,
      });

      axios.post(urlConstants.BASE_URL + "/" + this.props.type + "/login", {
        email: this.state.email,
        password: this.state.password
      })
        .then(res => {
          console.log(res.data.facility)
          // localStorage.setItem('user_name',res.data.facility.name)
          // if (this.props.type !== 'user' && res.data.stripeUrl) {
          //   let r = window.confirm("We detected that you don't have a Stripe account. You can use the app for authorization without an account, but will need one for payments. Sign up for Stripe before continuing?");
          //   if (r) {
          //     window.location.href = res.data.stripeUrl;
          //   }
          // }
          // if (!res.data[this.props.type].verified) {
          //   confirmAlert({
          //     title: ' Verify your email',
          //     message: 'Please verify your email. Please refresh once you\'ve completed this step',
          //     buttons: [
          //       {
          //         label: 'Send email',
          //         onClick: () => {
          //           axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/sendVerifyEmail').finally(() => {
          //             alert('Please check your email for the verification email');
          //             this.setState({isSubmitted: true, error: false, valid: true})
          //           });
          //         }
          //       }
          //     ],
          //     closeOnClickOutside: false,
          //   });
          // } else {
            this.setState({isSubmitted: true, error: false, valid: true});
          // }
        })
        .catch(err => {
          if (err && err.response && err.response.status === 401) {
            alert("Invalid credentials - please try again");
          } else {
            alert("There was an unknown error. Please check your internet or try again later.");
          }
          this.setState({error: true, isSubmitted: false, invalid: true})
        })
        .finally(() => {
          this.setState({
            submitting: false,
          })
        })
    }

    render() {
      if (this.state.open) {
        return <Redirect to="/home" push={true} />
      }
      if (this.state.valid) {
        let queryParams = qs.parse(this.props.location.search);
        if (queryParams.transactionId) {
          return <Redirect to={{pathname: this.state.routePrepend + '/signature', state: {transactionId: queryParams.transactionId, attachmentLinks: queryParams.attachmentLinks}}} push={true} />
        }
        return <Redirect to={this.state.routePrepend + "/admin/dashboard"} push={true} />
      }

      return (

        <div className="container-fluid login_singup_wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="left_side">
                <img src={login}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_side_form singin">
                <div className="login_form">
                  <a href="/"><img src={footerlogo}/></a>
                  <h2>Sign In</h2>
                  <form className="FormFields" onSubmit={this.handleSubmit}>
                    <div className="FormField">
                      <label className="FormField__Label" htmlFor="email">Email </label>
                      <input type="email" autoFocus="True" id="email" className="FormField__Input" placeholder= "Email"
                      name="email" value = {this.state.email} onChange={this.handleChange}/>
                    </div>

                    <div className="FormField">
                      <label className="FormField__Label" htmlFor="password">Password</label>
                      <input type="password" id="password" className="FormField__Input" placeholder= "Password"
                             name="password" value={this.state.password} onChange={this.handleChange} />
                             <NavLink exact to={(this.props.type === 'facility' ? this.props.type : this.state.routePrepend) + "/forgotPassword"} className="FormField__Link">Forgot Password?</NavLink>
                    </div>
                    <div className="FormField btns">
                      <Box sx={{m: 1, position: 'relative'}} >
                        <Button type="submit" disabled={this.state.submitting} color="primary">Sign In</Button>
                        {this.state.submitting && <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '30%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />}
                      </Box>
                    </div>

                    <div className="FormField_AlternateLink">
                      <span>Don’t have an account? <NavLink exact to={this.state.routePrepend + "/signUp"} className="FormField__Link">Sign Up</NavLink></span>
                    </div>
                  </form>
                </div>
            {this.state.isSubmitted && <p className="notification">Success! Logging in...</p> }
            {this.state.error && <p className="notification"> Could not login. Please try again</p> }
              </div>
            </div>
          </div>
        </div>
      );
    }
}
export default SignInForm;
