import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import StripeLogo from "assets/img/strip.png";
import AppLogo from "assets/img/white-logo.png";
import Email from "assets/img/email.png";
import Call from "assets/img/call.png";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import axios from "axios";
import urlConstants from "../../variables/urlConstants";

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes, showStripe, type } = props;
  var links = (
    <div className="new_sidebar">
    <List className={classes.list}>
      <br/><br/>
      {routes.filter(route => route.path !== '/archive').map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <span className={prop.name}>

                </span>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={
                  props.rtlActive ? prop.rtlName : prop.name
                }
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      {showStripe === true &&
        <ListItem button className={classes.itemLink} onClick={() => {
          axios.get(urlConstants.BASE_URL + '/' + type + '/stripeUrl')
          .then(res => {
            window.open(res.data, '_blank');
          })
          .catch(err => {
            alert("Can't seem to access Stripe dashboard now. Please try again later");
          })
        }}>
          <span>
            <img src={StripeLogo} alt="iBottt logo" height={24} width={24} style={{float: "left", marginRight: "15px"}}/>
          </span>
          <ListItemText
            primary="Stripe Dashboard"
            className={classNames(classes.itemText, {
              [classes.itemTextRTL]: props.rtlActive
            })}
            disableTypography={true}
          />
        </ListItem>
      }

      {routes.filter(route => route.path === '/archive').length > 0 &&
        routes.filter(route => route.path === '/archive').map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path)
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <span className={prop.name}>
                  {/*<img className={prop.name} src={prop.icon} alt="iBottt logo" height={24} width={24} style={{float: "left", marginRight: "15px"}}/>*/}
                </span>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  />
                )}
                <ListItemText
                  primary={
                    props.rtlActive ? prop.rtlName : prop.name
                  }
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive
                  })}
                  disableTypography={true}
                />
              </ListItem>


            </NavLink>

          );
        })

      }

        <ListItem button className={classes.itemLink} onClick={() => {
            window.open("mailto:techsupport@ibottt.com", '_blank');
        }} className="link_new_add">
                <span>
                  <img src={Email} alt="iBottt logo" height={24} width={24} style={{float: "left", marginRight: "15px"}}/>
                </span>
            <ListItemText
                primary="techsupport@ibottt.com"
                className={classNames(classes.itemText, {
                    [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
            />
        </ListItem>

        <ListItem button className={classes.itemLink} onClick={() => {
            window.open("tel:6507626389", '_blank');
        }} className="link_new_add last">
                <span>
                  <img src={Call} alt="iBottt logo" height={24} width={24} style={{float: "left", marginRight: "15px"}}/>
                </span>
            <ListItemText
                primary="6507626389"
                className={classNames(classes.itemText, {
                    [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
            />
        </ListItem>
    </List>
    </div>
  );

  const divStyle={
    color: '#51A9DD',
    backgroundColor: 'transparent',
    fontSize: "50px",
    textAlign: "center"
  };
  var brand = (
    <div className={"sidebar-logo " + classes.logo}>
      <a
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={divStyle}>
          <img src={AppLogo} alt="logo"  className="max-www" style={{margin: "10px auto !important"}}/>
        </div>

        <div style={divStyle}>
        {logoText}
        </div>
      </a>
    </div>
  );
  return (
    <div className="new_sidebar"> 
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
            <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={"sidebar-bg "+ classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={" sidebar-wrapper " + classes.sidebarWrapper} >{links}</div>
          {image !== undefined ? (
            <div
              className={"sidebar-bg "+ classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
