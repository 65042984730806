import React from "react";
import Modal from 'react-modal';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

import axios from "axios";

import urlConstants from '../../variables/urlConstants';
import {Button} from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";

const styles = {
  cardCategoryWhite: {
    color: "#27d7fa",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      name: '',
      phoneNumber: '',
      address: '',
      logoSrc: '',
      confirm: '',
      stripeEmail: '',
      taxId: '',
      errorMessage: '',
      linkStatus: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  };

  componentDidMount(){
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/info')
      .then(res => {
        this.setState({
          name: res.data.name,
          phoneNumber: res.data.phoneNumber,
          address: res.data.address,
          email: res.data.email,
          taxId: res.data.taxId,
          accountType: res.data.accountType
        });
      });
    this.getLogo();
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/stripeLinkStatus')
    .then(res => {
      this.setState({
        linkStatus: res.data.status,
        linkEmail: res.data.email
      })
    });
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/linkedInfo')
    .then(res => {
      this.setState({
        linkedAccounts: res.data.linkedAccounts,
        linkRequests: res.data.linkRequests
      });
    });
  }

  getLogo() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/icon')
    .then(res => {
      this.setState({
        logoSrc: res.data
      });
    });
  }

  setLogo() {
    var logoInput = document.getElementById('iconFile').files;
    if (logoInput.length > 0) {
      var reader = new FileReader();
      var fileToLoad = logoInput[0];
      if (fileToLoad.size / 1024 / 1024 > 10) {
        alert("Icon cannot exceed 10 MB!");
        return;
      }
      var base64;
      reader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target.result;
        axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/icon', {
          icon: base64
        })
        .then(() => {
          this.getLogo();
        });
      };
      reader.readAsDataURL(fileToLoad);
    }
  }

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  openDashboard() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/stripeUrl')
    .then(res => {
      window.open(res.data, '_blank');
    })
    .catch(err => {
      alert("Can't seem to access Stripe dashboard now. Please try again later");
    })
  }

  openModal() {
    this.setState({
      isOpen: true
    });
  }

  closeModal() {
    this.setState({
      isOpen: false
    });
  }

  isValidEmail(email) {
    return email.match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/);
  }

  linkStripe() {
    this.setState({
      errorMessage: ''
    });
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/linkStripe', {
      email: this.state.stripeEmail
    })
    .then(() => {
      this.setState({
        errorMessage: 'Account link request successfully sent!',
        linkStatus: 'LINKING',
        linkEmail: this.state.stripeEmail
      });
    })
    .catch(err => {
      let errMessage = 'Please try again later.';
      if (err && err.response && err.response.status === 404) {
        errMessage = 'No users found with that email'
      } else if (err && err.response && err.response.status === 400) {
        errMessage = 'That user needs to create a stripe account first before linking accounts.';
      }
      this.setState({
        errorMessage: errMessage
      });
    })
  }

  unlinkStripe() {
    this.setState({
      linkErrorMessage: ''
    });
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/unlinkStripe')
    .then(() => {
      this.setState({
        linkErrorMessage: 'Successfully cancelled Stripe account link request',
        linkStatus: 'UNLINKED',
      })
    })
    .catch(() => {
      this.setState({
        linkErrorMessage: 'Please try again later'
      })
    })
  }

  approveLink(encodedData) {
    this.setState({
      linkErrorMessage: ''
    });
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/confirmLink', {
      otherFacilityEncodedData: JSON.parse(encodedData)
    })
    .then(() => {
      this.setState({
        linkErrorMessage: 'Successfully linked Stripe account. Refresh the page to see changes'
      })
    })
    .catch(err => {
      if (err && err.response && err.response.status === 403) {
        this.setState({
          linkErrorMessage: "Can't link accounts. Either someone else has canceled linking or you have already linked the accounts"
        })
      } else {
        this.setState({
          linkErrorMessage: 'Please try again later'
        })
      }
    })
  }

  declineLink(encodedData) {
    this.setState({
      linkErrorMessage: ''
    });
    axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/declineLink', {
      encodedData: JSON.parse(encodedData)
    })
    .then(() => {
      this.setState({
        linkErrorMessage: 'Declined Stripe account. Refresh the page to see changes'
      })
    })
    .catch(() => {
      this.setState({
        linkErrorMessage: 'Please try again later'
      })
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={18} sm={12} md={18}>
          <div className="ab_profile">
            <Card profile>
              {/*<h2>Facility Business Information</h2>*/}
              <br></br>
              <br></br>
              <div className="d-flex">
                <CardAvatar plain className="me-2">
                  <img src={this.state.logoSrc} alt="logo" style={{ maxWidth: 150, maxHeight: 150 }} />
                </CardAvatar>
                <div>

                  <button className="btn_upd" onClick={() => this.setLogo()} >Update Icon</button>
                </div >
              </div>
              <CardBody profile className="ab_body">
                <h4 className={classes.cardTitle}> {/*<span>Company Name:</span>*/} {this.state.name}</h4>
                <h6><span>Attachment:</span> <input type='file' id='iconFile' /></h6>
                <h6><span>Email:</span> {this.state.email}</h6>
                <h6><span>Tax ID:</span> {this.state.taxId}</h6>
                <h6><span>Account Type:</span> {this.state.accountType}</h6>
                <h6><span>Facility Address:</span> {this.state.address}</h6>
                <h6><span>Phone Number:</span> {this.state.phoneNumber}</h6>
                <div className="abt_wpas">
                <Button color="primary" onClick={() => this.openDashboard()}>Stripe Dashboard</Button>
                </div>
                {this.state.linkStatus === 'UNLINKED' && <Button color="primary" onClick={() => this.openModal()}>Link Stripe Account</Button>}
                {this.state.linkStatus === 'LINKING' && <Button onClick={() => this.unlinkStripe()} >Cancel linking to {this.state.linkEmail}</Button>}
                {this.state.linkStatus === 'LINKED' && <Button disabled={true}>Linked to {this.state.linkEmail}</Button>}
                {this.state.linkErrorMessage}
                {this.state.linkRequests &&
                  <div>
                    <h6>Link Requests</h6>
                    <ul>
                      {this.state.linkRequests.map(el => <li>{JSON.parse(el).email}
                        <Button onClick={() => this.approveLink(el)}>Approve</Button>
                        <Button onClick={() => this.declineLink(el)}>Decline</Button>
                      </li>)}
                    </ul>
                  </div>}
                {this.state.linkedAccounts &&
                  <div>
                    <h6>Linked accounts:</h6>
                    <ul>
                      {this.state.linkedAccounts.map(el => <li>{JSON.parse(el).email}</li>)}
                    </ul>
                  </div>}
              </CardBody>
            </Card>
          </div>
          </GridItem>
        </GridContainer>
        <Modal isOpen={this.state.isOpen} style={{ overlay: { zIndex: 1000 }}} >
          <div>
            <Button onClick={() => this.closeModal()}>Close</Button>
            <p>Use this to make all payments go to the account associated with the email below. The other person must
            have linked a Stripe account to begin this process. WARNING: this process may be irreversible</p>
            <CustomInput
              labelText="Email to link Stripe Accounts"
              inputProps={{
                name:"stripeEmail",
                value:this.state.stripeEmail,
                onChange:this.handleChange,
                error: !this.isValidEmail(this.state.stripeEmail)
              }}
              formControlProps={{
                fullWidth: true,
                required: true
              }} />
            <CustomInput
              labelText='Type "confirm" to confirm.'
              inputProps={{
                name: "confirm",
                value: this.state.confirm,
                onChange: this.handleChange
              }}
              formControlProps={{
                fullWidth: true,
                required: true
              }} />
            <Button disabled={this.state.confirm.toUpperCase() !== "CONFIRM" || !this.isValidEmail(this.state.stripeEmail)}
                    onClick={() => this.linkStripe()}>Link Stripe Account</Button>
            {this.state.errorMessage}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(UserProfile);
