import React, { Component } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';

import { stripeKey } from '../../variables/stripeKeys';
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import urlConstants from "../../variables/urlConstants";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.clientSecret = null;
    this.state = {
      stripeAccount: null
    }
  }

  componentDidMount() {
    if (this.props.type === 'user') {
      axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/paymentStripeAccount', {
        transactionId: this.props.transactionId
      }).then(res => {
        this.clientSecret = res.data.clientSecret;
        this.setState({
          stripeAccount: res.data.stripeAccount
        });
      });
    } else {
      axios.post(urlConstants.BASE_URL + '/' + this.props.type + '/paymentIntent', {
        transactionId: this.props.transactionId,
        idempotencyKey: this.idempotencyKey
      }).then(res => {
        this.clientSecret = res.data.clientSecret;
        this.setState({
          stripeAccount: res.data.stripeAccount
        });
      });
    }
  }

  render() {
    return (
      <div>
        {
          this.state.stripeAccount &&
            <StripeProvider apiKey={stripeKey} stripeAccount={this.state.stripeAccount} >
              <Elements>
                  <CheckoutForm type={this.props.type}
                                transactionId={this.props.transactionId}
                                balance={this.props.balance}
                                clientSecret={this.clientSecret}
                                successCallback={this.props.successCallback} />
              </Elements>
            </StripeProvider>
        }
      </div>
    )
  }
}

export default Payment;
