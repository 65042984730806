import React, {Component} from 'react';
import { Redirect } from "react-router";
import { NavLink } from 'react-router-dom';

import './App.css';
import logo3 from "./iAuthoLogo.png";
import axios from 'axios'

import login from "./login.png";
import footerlogo from "./footer-logo.png";

import urlConstants from '../variables/urlConstants';
import {confirmAlert} from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";

class SignUpForm extends Component{
    constructor(props){
      super(props);

      this.state = {
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        latitude: '',
        longitude: '',
        password: '',
        companyName: '',
        enrollmentType: 'employee',
        password_confirmation: '',
        hasAgreed: false,
        isSubmitted: false,
        error: false,
        routePrepend: ''
      };

      if (props.skipRegister) {
        this.state.routePrepend = '/insurance';
      } else if (props.type === 'user') {
        this.state.routePrepend = '/user';
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.typeChange = this.typeChange.bind(this);
    }

    state = {
      open: false
    };
    
    handleToggle = () => {
      this.setState(state => ({ open: !state.open}));
    };

    handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        });
    }

  handleRadioChange(e) {
    let target = e.target;
    let value = target.type === 'radio' ? target.checked : target.value;
    let name = target.name;
    // this.state.enrollmentType = target.value
  }

    handleSubmit(e) {
      e.preventDefault();
      if (!(this.state.password.length>=8)) {
        alert("passwords must be a minimum of 8 characters with a special character");
        return;
      } else if (this.state.password !== this.state.password_confirmation) {
        alert("passwords do not match");
        return;
      }
      this.setState({
        isSubmitted: null,
      })
      if (this.props.type !== 'user') {
        axios.post(urlConstants.BASE_URL + "/" + this.props.type + "/register", {
          name: this.state.name,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
          address: this.state.address + ', ' + this.state.city + ', ' + this.state.state + ' ' + this.state.zipCode,
          accountType: this.state.enrollmentType,
          password: this.state.password,
          companyName: this.state.companyName,
        })
        .then(() => {
          axios.post(urlConstants.BASE_URL + "/" + this.props.type + "/login", {
            email: this.state.email,
            password: this.state.password
          })
          .then(res => {
            if (res.data.stripeUrl && !this.props.skipRegister) {
              if (this.state.enrollmentType === 'manager') {
                let r = window.confirm("iBottt account created. You will need a Stripe account to accept payments. You can use the app for authorization without an account, but will need one for payments. Create a Stripe account now?");
                if (r) {
                  window.location.href = res.data.stripeUrl;
                }
              } else {
                window.alert('iBottt account created. Please see a manager to set up payments with Stripe.');
              }
            }
            this.setState({ isSubmitted: true, error: false, valid:true });
          })
          .catch(err => {
            alert('Account created with iBottt. Please click sign in to log on');
            this.setState({
              isSubmitted: false
            })
          })
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400) {
            if (error.response.data && error.response.data.error && error.response.data.error.includes("exists")) {
              alert("User already exists. Please use another email");
            } else {
              alert("Please check that your address is valid");
            }
            this.setState({
              isSubmitted: false
            })
          } else {
            this.setState({
              error: true,
              isSubmitted: false
            })
          }
        });
      } else {
        let reader = new FileReader(), reader2 = new FileReader();
        reader.onloadend = () => {
          let image = new Image();
          image.src = reader.result;
          image.onload = () => {
            reader2.onloadend = () => {
              let image2 = new Image();
              image2.src = reader2.result;
              image2.onload = () => {
                let hRatio = 480  / image.width;
                let vRatio =  480 / image.height;
                let ratio  = Math.min(hRatio, vRatio);
                let ctx = this.canvasRef.getContext('2d');
                ctx.clearRect(0, 0, 480, 960);
                if (ratio < 1) {
                  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * ratio, image.height * ratio);
                } else {
                  ctx.drawImage(image, 0, 0, image.width, image.height);
                }
                hRatio = 480  / image2.width;
                vRatio =  480 / image2.height;
                let ratio2 = Math.min(hRatio, vRatio);
                if (ratio2 < 1) {
                  ctx.drawImage(image2, 0, 0, image2.width, image2.height, 0, image.height * Math.min(ratio, 1),
                    image2.width * ratio, image2.height * ratio);
                } else {
                  ctx.drawImage(image2, 0, image.height * Math.min(ratio, 1), image2.width, image2.height);
                }
                axios.post(urlConstants.BASE_URL + "/" + this.props.type + "/register", {
                  name: this.state.name,
                  email: this.state.email,
                  phoneNumber: this.state.phoneNumber,
                  photo: this.canvasRef.toDataURL('image/jpeg', 0.8).split(',')[1],
                  password: this.state.password
                }).then(() => {
                  axios.post(urlConstants.BASE_URL + "/" + this.props.type + "/login", {
                    email: this.state.email,
                    password: this.state.password
                  })
                  .then(res => {
                    this.setState({ isSubmitted: true, error: false, valid:true });
                  })
                  .catch(err => {
                    alert('Account created with iBottt. Please click sign in to log on');
                    this.setState({
                      isSubmitted: false
                    })
                  })
                })
                .catch(error => {
                  if (error && error.response && error.response.status === 400) {
                    alert("User already exists. Please use another email");
                    this.setState({
                      isSubmitted: false
                    })
                  } else {
                    this.setState({
                      error: true,
                      isSubmitted: false
                    })
                  }
                });
              };
            };
            reader2.onerror = () => {
              alert('Please make sure you have uploaded an image of the back of your license');
              this.setState({
                isSubmitted: false
              })
            };
            reader2.readAsDataURL(this.backLicenseRef.files[0]);
          };
        };
        reader.onerror = () => {
          alert('Please make sure you have uploaded an image of the front of your license');
          this.setState({
            isSubmitted: false
          })
        };
        reader.readAsDataURL(this.frontLicenseRef.files[0]);
      }
    }

  typeChange(e) {
    let target = e.target
    this.setState({
      enrollmentType: target.value
    });
  }
        
    render(){
      if (this.state.valid) {
        return <Redirect to={this.state.routePrepend + "/admin/dashboard"} push={true} />
      }
      if (this.state.isSubmitted) {
        return <Redirect to={this.state.routePrepend + "/signIn"} push={true} />
      }
      if (this.state.open) {
        return <Redirect to="/home" push={true} />
      }
      return (
        <div className="container-fluid login_singup_wrapper sngup">
          
          <div className="row">
            <div className="col-md-6">
              <div className="left_side">
                <img src={login}/>
              </div>
            </div>
            <div className="col-md-6">
            <div className="right_side_form sing_up">
              <div className="login_form signup_wrpa">
                <a href="/"><img src={footerlogo}/></a>
                <h2>Sign Up</h2>              
                <form className="FormFields" onSubmit={this.handleSubmit}>

                  {this.props.type !== 'user' &&
                    <div className="FormField full">
                      <label className="FormField__Label" htmlFor="enrollment">Enrollment type</label>
                      {/*<select className="fddsaf" name="enrollment" id="enrollment" value={this.state.enrollmentType} onChange={this.typeChange}>
                        <option value="manager">Facility Manager Enrollment</option>
                        <option value="employee">Facility Employee Enrollment</option>
                      </select>*/}
                      <div className="form-check">
                        <input className="form-check-input" value="manager" type="radio" name="enrollmentType" id="flexRadioDefault1" onChange={this.handleRadioChange}/>
                        <label className="form-check-label" for="flexRadioDefault1">
                          Facility Manager
                        </label>
                      </div>
                      <div className="form-check">                        
                        <input className="form-check-input" value="employee" type="radio" name="enrollmentType" id="flexRadioDefault2"  onChange={this.handleRadioChange} defaultChecked/>
                        <label className="form-check-label" for="flexRadioDefault2">
                          Facility Employee
                        </label>
                      </div>
                    </div>
                  }

                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="name">{this.props.type === 'user' ? 'Name' : (this.props.type === "insurance" ? "Name" : "Name")}</label>
                    <input required type="text" id="name" className="FormField__Input" placeholder= "Full Name" name="name" value={this.state.name} onChange = {this.handleChange}/>
                  </div>
                  { this.props.type !== 'user' &&
                    <div className="FormField full">
                      <label className="FormField__Label" htmlFor="companyName">Company Name</label>
                      <input required type="text" id="companyName" className="FormField__Input" placeholder= "Company Name" name="companyName" value={this.state.companyName} onChange = {this.handleChange}/>
                    </div>
                  }
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="email">Email </label>
                    <input required pattern=".+@.{2,}" type="text" id="email" className="FormField__Input" placeholder= "Email Address" name="email" value={this.state.email} onChange = {this.handleChange}/>
                  </div>
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="phone">Phone Number </label>
                    <input required type="tel" id="phone" className="FormField__Input" placeholder= "Phone Number" name="phoneNumber" value={this.state.phoneNumber} onChange = {this.handleChange}/>
                  </div>
                  {this.props.type !== 'user' &&
                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="Address">Address</label>
                    <input required type="text" id="Address" className="FormField__Input" placeholder= "Address" name="address" value={this.state.address} onChange = {this.handleChange}/>
                  </div>
                  }
                  {this.props.type !== 'user' &&
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="State">State</label>
                    <input required type="text" id="State" className="FormField__Input" placeholder="State" name="state" value={this.state.state} onChange = {this.handleChange}/>
                  </div>
                  }
                  {this.props.type !== 'user' &&
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="City">City</label>
                    <input required type="text" id="City" className="FormField__Input" placeholder="City" name="city" value={this.state.city} onChange = {this.handleChange}/>
                  </div>
                  }
                  
                  {this.props.type !== 'user' &&
                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="ZipCode">Zip Code</label>
                    <input required type="text" id="ZipCode" className="FormField__Input" placeholder="Zip Code" name="zipCode" value={this.state.zipCode} onChange = {this.handleChange}/>
                  </div>
                  }
                  
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="password">Password</label>
                    <input required pattern=".{8,}" type="password" id="password" className="FormField__Input" placeholder= "Enter Password - min 8 char w/ 1 number, 1 special char"
                    name="password" value={this.state.password} onChange={this.handleChange} />
                  </div>
                  <div className="FormField">
                    <label className="FormField__Label" htmlFor="password">Retype Password</label>
                    <input required type="password" id="password_confirmation" className="FormField__Input" placeholder= "Retype Password"
                    name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} />
                  </div>
                  {this.props.type === 'user' &&
                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="frontLicense">Driver’s License (Front)</label>
                    <input required type="file" id="frontLicense" name="frontLicense" accept="image/*"
                           ref={(ref) => this.frontLicenseRef = ref}/>
                  </div>
                  }
                  {this.props.type === 'user' &&
                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="backLicense">Driver’s License (Back)</label>
                    <input required type="file" id="backLicense" name="backLicense" accept="image/*"
                           ref={(ref) => this.backLicenseRef = ref} />
                  </div>
                  }
                  
                  <div className="FormField btnsup">
                    <Box sx={{m: 1, position: 'relative'}} >
                      <Button id='signup_button' color='primary' type='submit' disabled={this.state.isSubmitted === null}>Sign Up</Button>
                      {this.state.isSubmitted === null && <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '30%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />}
                    </Box>
                  </div>
                  <div className="FormField_AlternateLink">
                      <span>Already an account? <NavLink exact to={this.state.routePrepend + "/signIn"} className="FormField__Link">Sign In</NavLink></span>
                    </div>
                </form>
                <canvas id="canvas" ref={ref => this.canvasRef = ref} width={480} height={960} hidden />
                {this.state.isSubmitted && <p>Success! Creating Account...</p> }
                {this.state.error && <p>Looks like there is an error. Please ensure all fields are entered correctly, or the account may have already been created.</p> }
              </div>
            </div>
            </div>
          </div>
        </div>
      );
    }
}
export default SignUpForm;