import React, { Component } from 'react';
import { Redirect } from "react-router";
import Dropzone from "react-dropzone";

import axios from 'axios';
import Mime from '../../utils/mimeType';

import urlConstants from '../../variables/urlConstants';
import extractor from '../../handlers/pdfTextExtractor';
import pdfParser from '../../handlers/cccPdfParser';
import checkbox from './checkbox.png';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Grid from "@material-ui/core/Grid";
import {appendPageToPDF} from "../../handlers/pdfGenerator";
import {PDFDocument} from 'pdf-lib';
import Modal from "react-modal";
import ProfileModal from "../ProfileModal/ProfileModal";
import VehicleInfoModal from "../VehicleInfoModal/VehicleInfoModal";
import xpath from 'xpath';
import {DOMParser} from 'xmldom';
import Close from "@material-ui/icons/Close"
import {CircularProgress, InputAdornment} from "@material-ui/core";
import LinearLoadingDeterminate from '../../components/LoadingBars/LinearLoadingDeterminate';
import Box from "@material-ui/core/Box";

//import 'bootstrap/dist/css/bootstrap.css';

const targetGroupNameMap = {
  'user': 'Customer',
  'facility': 'Facility',
  'insurance': 'Insurance'
};
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    
    marginBottom: "3px",
    textDecoration: "none"
  },
  infoButton: {
    marginTop: "35px",
    padding: "3px 15px 3px 15px",
    fontSize: "22px",
    textTransform: "lowercase",
    fontWeight: "bold"
  },
  bottomAlign: {
    marginTop: "35px"
  },
  characterPadded: {
    marginLeft: "8px",
    marginRight: "8px"
  },
  questionPadded: {
    marginLeft: "5px",
    marginRight: "5px"
  }
};
const extractionLoading = 'Extracting PDF… This may take a few seconds';
const extractionFailed = 'Extracting failed… Please try again by reuploading the PDF or enter data in manually';

class NewTransaction extends Component {
  constructor(props){
    super(props);

    this.state = {
      vin: '',
      userEmail: '',
      invoiceAmount:'',
      fileData: '',
      subjectLine: '',
      roNumber: '',
      communicationType: this.props.allowTypeSelection ? 'authorization' : 'onboard',
      toggleActive: false,
      isSubmitted: false,
      submitEnabled: true,
      error: false,
      open: false,
      allowTypeSelection: this.props.allowTypeSelection,
      targetGroup: this.props.targetGroup,
      files: [],
      showVehicleDetails: false,
      licenseNum: '',
      vehicleDetails: '',
      modalOpen: false,
      estimateId: '',
      cccOneEnabled: false,
      paymentSetupWarning: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.changeTargetGroupType = this.changeTargetGroupType.bind(this);
  }

  componentDidMount() {
    if (this.props.roNumber) {
      this.handleChange({
        target: {
          name: 'roNumber',
          value: this.props.roNumber
        }
      });
    }
    if (this.props.vin) {
      this.handleChange({
        target: {
          name: 'vin',
          value: this.props.vin
        }
      });
    }
    if (this.props.userEmail) {
      this.handleChange({
        target: {
          name: 'userEmail',
          value: this.props.userEmail
        }
      });
    }

    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/info').then(res => {
      if (!res.data.stripe_id) {
        this.setState({
          paymentSetupWarning: 'Payments have not been set up. Please don\'t enter anything other than 0 here'
        })
      }
    })
  }

  changeTargetGroupType(e) {
    let newType = e.target.value;
    this.handleChange({
      target: {
        name: 'userEmail',
        value: ''
      }
    })
    this.setState({
      targetGroup: newType,
    })
  }

  extractPdf(selectedFiles) {
    this.setState({
      extractionWarning: ''
    });
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (Mime.lookup(selectedFiles[i].name) !== 'application/pdf') {
          continue;
        }
        let fileToLoad = selectedFiles[i];
        this.setState({
          extractionWarning: extractionLoading,
        });
        extractor.extractFields(fileToLoad)
            .then(fieldsJson => {
              this.setState({
                extractionWarning: 'PDF extracted. Please double check as some fields may have changed'
              });

              let vin = fieldsJson.vin;
              let invoiceAmount = fieldsJson.netCost;
              let licenseNum = fieldsJson.licenseNum;
              let miles = fieldsJson.miles;
              let details = fieldsJson.vehicleDetails;
              let roNumber = fieldsJson.roNumber;
              let netCost = fieldsJson.netCost;
              let customerInfo = {
                name: fieldsJson.ownerFirstName + ' ' + fieldsJson.ownerLastName,
                address: fieldsJson.customerAddress,
                phoneNumber: fieldsJson.customerPhoneNum,
              };

              if (vin) {
                this.setState({vin: vin});
              } else {
                this.setState({vin: ''});
              }

              if (invoiceAmount !== undefined && !this.state.toggleActive) {
                this.setState({invoiceAmount: invoiceAmount});
              }

              if (licenseNum) {
                this.setState({licenseNum: licenseNum, showVehicleDetails: true});
              } else {
                this.setState({licenseNum: '', showVehicleDetails: true});
              }

              if (miles) {
                this.setState({miles: miles, showVehicleDetails: true});
              } else {
                this.setState({miles: '', showVehicleDetails: true});
              }

              if (details) {
                this.setState({vehicleDetails: details, showVehicleDetails: true});
              } else {
                this.setState({vehicleDetails: '', showVehicleDetails: true});
              }

              if (customerInfo) {
                this.setState({ extractedCustomerInfo: customerInfo ?? '', name: customerInfo.name ?? '', address: customerInfo.address ?? '', phoneNum: customerInfo.phoneNumber ?? ''});
              }
              if (roNumber) {
                this.setState({ subjectLine: roNumber + ': ' + this.state.subjectLine });
              }
              if (netCost) {
                this.setState({invoiceAmount: netCost});
              }
            })
            .catch(err => {
              this.setState({
                extractionWarning: extractionFailed,
              });
            });
        break;
      }
    }
  }

  handleToggle() {
    this.setState(state => ({ open: !state.open}));
  };

  handleChange(e) {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
    if (name === 'userEmail') {
      this.retrieveUserDetails(e);
    }
  }

  async postTransaction(userId, selectedFiles, addTimelineRelease) {
    const formData = new FormData();
    formData.append('vin', this.state.vin);
    if (this.props.type === 'user') {
      formData.append('companyInfo', userId);
    } else {
      formData.append('userId', userId);
    }
    formData.append('userType', this.state.targetGroup);
    formData.append('invoiceAmount', this.state.invoiceAmount);
    formData.append('roNumber', this.state.roNumber);
    if (this.state.communicationType === 'message') {
      formData.append('isMessage', 'true');
      let emailList = this.state.userEmail.split(", ");
      if (emailList.length > 0) {
        formData.append('email', emailList[0]);
      }
      for (let email of emailList) {
        formData.append('sendList[]', email);
      }
    } else {
      formData.append('email', this.state.userEmail);
    }
    let masterPdf;
    for (let i = 0; i < selectedFiles.length; i++) {
      if (masterPdf && selectedFiles[i].type === 'application/pdf') {
        masterPdf = await PDFDocument.load(masterPdf);
        let file = selectedFiles[i];
        const bytes = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
        });
        let pdf2 = await PDFDocument.load(bytes);
        masterPdf = new Blob([await appendPageToPDF(masterPdf, pdf2)]);
      } else if (selectedFiles[i].type === 'application/pdf') {
        masterPdf = await selectedFiles[i].arrayBuffer();
      } else {
        formData.append('serviceDescription', selectedFiles[i], selectedFiles[i].name);
      }
    }

    // TODO: revisit adding timeline release
    // if (masterPdf && addTimelineRelease) {
    //   let res = await axios.get(urlConstants.BASE_URL + '/facility/info');
    //   const fileReader = new FileReader();
    //   const fileBytes = await new Promise((resolve) => {
    //     if (isArrayBuffer(masterPdf)) {
    //       masterPdf = new Blob([masterPdf])
    //     }
    //     fileReader.readAsArrayBuffer(masterPdf);
    //     fileReader.onload = () => {
    //       resolve(fileReader.result);
    //     }
    //   })
    //   let pdf1 = await PDFDocument.load(fileBytes);
    //   // release page
    //   const doc = new PDFKit();
    //   const stream = doc.pipe(blobStream());
    //   await createTimelineReleasePDF(doc, res.data.name, res.data.email, this.state.name,
    //     this.state.vin, this.state.details)
    //   const bytes = await new Promise((resolve) => {
    //     stream.on('finish', () => {
    //       const blob = stream.toBlob('application/pdf');
    //       var fileReader = new FileReader();
    //       fileReader.onload = (event) => {
    //         var bytes = new Uint8Array(event.target.result);
    //         resolve(bytes);
    //       }
    //       fileReader.readAsArrayBuffer(blob);
    //     })
    //   });
    //   let pdf2 = await PDFDocument.load(bytes);
    //   masterPdf = await appendPageToPDF(pdf1, pdf2);
    //   formData.append('serviceDescription', new Blob([masterPdf], {type: 'application/pdf'}), 'name.pdf')
    // } else if (addTimelineRelease) {
    //   let res = await axios.get(urlConstants.BASE_URL + '/facility/info');
    //   const doc = new PDFKit();
    //   const stream = doc.pipe(blobStream());
    //   await createTimelineReleasePDF(doc, res.data.name, res.data.email, this.state.name,
    //     this.state.selectedVin, this.state.details)
    //   const blob = await new Promise((resolve) => {
    //     stream.on('finish', () => {
    //       const blob = stream.toBlob('application/pdf');
    //       resolve(blob);
    //     })
    //   });
    //   formData.append('serviceDescription', blob);
    // }
    if (masterPdf) {
      formData.append('serviceDescription', new Blob([masterPdf], {type: 'application/pdf'}), 'name.pdf');
    }
    formData.append('subjectLine', this.state.subjectLine);
    let url = urlConstants.BASE_URL + "/" + this.props.type + "/transaction";
    if (addTimelineRelease) {
      url = urlConstants.BASE_URL + "/" + this.props.type + "/timelineRelease";
    }
    return axios.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(res => {
      if (url.endsWith("/timelineRelease")) {
        window.location.reload(false);
      }
      return res;
    })
  }

  async updateTargetGroup(userId, targetGroup) {
    if (targetGroup === 'user' && this.state.name) {
      let data = {
        userId: userId,
        name: this.state.name
      };
      if (this.state.address) {
        data.address = this.state.address;
      }
      if (this.state.phoneNum) {
        data.phoneNumber = this.state.phoneNum;
      }
      await axios.patch(urlConstants.BASE_URL + '/' + this.props.type + '/updateUserInfo', data);
    }
    this.setState({
      targetGroup: targetGroup
    });
  }

  async handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.state.communicationType === 'onboard') {
      await this.onboardUser();
    }
    if (this.state.files.length === 0 && this.state.communicationType !== 'message') {
      window.alert('Please add a pdf attachment to the authorization.');
      return;
    }
    this.setState({
      error: false,
      isSubmitted: false,
      submitEnabled: false
    });
    if (this.state.files.length > 10) {
      alert("You may only upload up to 10 files at once");
      return;
    }
    var selectedFile = this.state.files;
    let email = this.state.userEmail;
    if (this.state.communicationType === 'message') {
      email = this.state.userEmail.split(/, */)[0]
    }
    let vinData = {
      vin: this.state.vin
    };
    if (this.state.licenseNum) {
      vinData['licenseNum'] = this.state.licenseNum;
    }
    if (this.state.vehicleDetails) {
      vinData['details'] = this.state.vehicleDetails;
    }
    if (this.state.miles) {
      vinData['miles'] = this.state.miles;
    }
    axios.patch(urlConstants.BASE_URL + '/vehicle/vin', vinData)
      .then(() => {
        return axios.get(urlConstants.BASE_URL + "/" + this.props.type + "/lookupByEmail?email=" +
          encodeURIComponent(email) + "&type=unknown");
      })
      .then(async res => {
        if (!res.data.userId) {
          alert('User email not found! Please onboard user');
        } else {
          let response = await axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/timelineRelease?vin=' + this.state.vin);
          let requestTimelineRelease = response.data === false;
          try {
            await this.updateTargetGroup(res.data.userId, res.data.userType);
          } catch (err) {
            console.log(err);
          }
          this.postTransaction(res.data.userId, selectedFile, requestTimelineRelease)
            .then(() => {
              this.setState({isSubmitted: true, error: false});
              alert('Successfully added transaction');
              if (this.props.successCallback) {
                this.props.successCallback();
              }
            })
            .catch(err => {
              console.error('post transaction failed')
              console.error(err);
              if (err && err.response && err.response.status === 400) {
                this.setState({
                  error: "Please check that you have filled out the following fields: email, vin & quick description (if you're" +
                    "sending an authorization or message if you're sending a message)",
                  isSubmitted: false
                });
              } else {
                this.setState({
                  error: "Unknown error. Please try again later.",
                  isSubmitted: false
                });
              }
            }).finally(() => {
            this.setState({
              submitEnabled: true
            });
          });
        }
      }).catch(error => {
        console.error('vehicle vin failed')
        if (error && error.response && error.response.status === 404) {
          alert('Please onboard this user first before creating a new authorization');
        } else if (error && error.response && error.response.status >= 400 && error.response.status < 500) {
          this.setState({
            error: "Please check your fields",
            isSubmitted: false
          });
        } else {
          this.setState({
            error: "Please try again later.",
            isSubmitted: false
          })
        }
        this.setState({
          submitEnabled: true
        });
      });
  }

  onboardUser() {
    this.setState({
      error: false,
      isSubmitted: false,
      submitEnabled: false
    });
    let data = {
      email: this.state.userEmail,
      vin: this.state.vin,
    };
    if (this.state.userDetailsFound && this.state.userDetailsFound.name) {
      data.name = this.state.userDetailsFound ? this.state.userDetailsFound.name : '';
    }
    return axios.post(urlConstants.BASE_URL + "/facility/addUserLink", data)
    .then(async res => {
      await this.updateTargetGroup(res.data.link, 'user');
      let patchData = {
        vin: this.state.vin,
        owner: res.data.link,
      }
      if (this.state.licenseNum) {
        patchData.licenseNum = this.state.licenseNum;
      }
      if (this.state.vehicleDetails) {
        patchData.details = this.state.vehicleDetails;
      }
      if (this.state.miles) {
        patchData.miles = this.state.miles;
      }
      await axios.patch(urlConstants.BASE_URL + '/vehicle/vin', patchData);
      await axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/allTransactions?vin=' + this.state.vin);
      this.setState({isSubmitted: true, error: false, returnToDashboard: true});
    })
    .catch(err => {
      if (err && err.response && err.response.status === 400) {
        axios.get(urlConstants.BASE_URL + '/facility/lookupByEmail?email=' + data.email + '&type=facility')
        .then(async res => {
          let patchData = {
            vin: this.state.vin,
          };
          if (this.state.licenseNum) {
            patchData.licenseNum = this.state.licenseNum;
          }
          if (this.state.vehicleDetails) {
            patchData.details = this.state.vehicleDetails;
          }
          if (this.state.miles) {
            patchData.miles = this.state.miles;
          }
          await axios.patch(urlConstants.BASE_URL + '/vehicle/vin', patchData);
          await axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/allTransactions?vin=' + this.state.vin);
          this.setState({isSubmitted: true, error: false, returnToDashboard: true});
        })
        .catch(err => {
          this.setState({
            error: "That email has been registered and recognized.",
            isSubmitted: false,
            submitEnabled: true,
          });
        });
      } else {
        this.setState({
          error: "Unable to onboard user. Please try again later.",
          isSubmitted: false,
          submitEnabled: true,
        });
      }
    });
  }

  typeChange(e) {
     let target = e.target
     this.setState({
       communicationType: target.value
     });
  }

  onToggle() {
    if (this.state.toggleActive) {
     this.setState({
       toggleActive: !this.state.toggleActive
      });
    } else {
      this.setState({
        invoiceAmount: 0,
        toggleActive: !this.state.toggleActive
      })
    }
  }

  retrieveUserDetails(e) {
    let target = e.target;
    let value = target.value;

    this.setState({
      userDetailsFound: null,
      signUpWarning: false
    });
    clearTimeout(this.profileTimeout);

    this.profileTimeout = setTimeout(() => {
      axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupByEmail?type=unknown' +
        '&email=' + encodeURIComponent(value))
      .then(res => {
        let jsonResult = res.data.Items[0];
        if (jsonResult.name && !this.state.name) {
          this.setState({
            name: jsonResult.name
          });
        }
      })
      .catch(err => {
        if (err && err.response && err.response.status === 404 && err.response.data) {
          let jsonResult = err.response.data.Item;
          if (jsonResult.name && !this.state.name) {
            this.setState({
              name: jsonResult.name
            });
          }
        }
      })
    }, 500);
  }

  extractEstimateData(files) {
    this.setState({
      extractionWarning: ''
    });

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let fr;
        if (files[i].name.endsWith('.ad1')) {
          fr = new FileReader();
          fr.onload = (fileLoadedEvent) => {
            let contents = fileLoadedEvent.target.result;
            let lastLine = contents.split('\n');
            if (lastLine.length > 0) {
              lastLine = lastLine[lastLine.length - 1];
              let lastLineFields = this.stripAllWeirdCharacters(lastLine).trim().split(/ {2,}/);
              if (lastLineFields.length >= 7) {
                this.setState({
                  userEmail: lastLineFields[lastLineFields.length - 1],
                  name: lastLineFields[lastLineFields.length - 6] + " " + lastLineFields[lastLineFields.length - 7],
                });
              }
            }
          };
          fr.readAsText(files[i]);
        } else if (files[i].name.endsWith('.ttl')) {
          if (this.state.communicationType === 'authorization') {
            fr = new FileReader();
            fr.onload = (fileLoadedEvent) => {
              let contents = fileLoadedEvent.target.result;
              let lastLine = contents.split('\n');
              if (lastLine.length > 0) {
                lastLine = lastLine[lastLine.length - 1];
                let lastLineFields = this.stripAllWeirdCharacters(lastLine).trim().split(/ {2,}/);
                if (lastLineFields.length > 0 && !isNaN(parseFloat(lastLineFields[lastLineFields.length - 1]))) {
                  this.setState({
                    invoiceAmount: parseFloat(lastLineFields[0]),
                  });
                }
              }
            };
            fr.readAsText(files[i]);
          }
        } else if (files[i].name.endsWith('.veh')) {
          fr = new FileReader();
          fr.onload = (fileLoadedEvent) => {
            let contents = fileLoadedEvent.target.result;
            let lastLine = contents.split('\n');
            if (lastLine.length > 0) {
              lastLine = lastLine[lastLine.length - 1];
              let lastLineFields = this.stripAllWeirdCharacters(lastLine).trim().split(/ {2,}/);
              if (lastLineFields.length > 1) {
                this.setState({
                  vin: lastLineFields[1],
                });
              }
            }
          };
          fr.readAsText(files[i]);
        }
      }
      this.setState({
        extractionWarning: 'Fields extracted. Please double check as some fields may have changed'
      });
    }
  }

  stripAllWeirdCharacters(str) {
    str = str.replace(/^[^A-Za-z0-9]+/, '');
    str = str.replace(/[^A-Za-z0-9]+$/, '');
    return str;
  }

  populateEmails() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/notifiedUsers?vin=' + this.state.vin)
    .then(res => {
      let emails = [];
      for (let datum of res.data) {
        emails.push(datum.email)
      }
      this.setState({
        userEmail: emails.join(", ")
      })
    });
  }

  decodeVin() {
    axios.get(urlConstants.BASE_URL + '/vehicle/decodeVin?vin=' + this.state.vin)
      .then(res => {
        if (!this.state.licenseNum) {
          this.setState({
            showVehicleDetails: true,
            licenseNum: res.data.licenseNum,
            vehicleDetails: res.data.vehicleDetails
          })
        } else {
          this.setState({
            showVehicleDetails: true,
            vehicleDetails: res.data.vehicleDetails
          })
        }
        this.setState({
          decodeVinWarning: '',
        });
      })
      .catch(err => {
        if (err && err.response && err.response.status === 400) {
          this.setState({
            decodeVinWarning: err.response.data,
          });
        } else {
          this.setState({
            decodeVinWarning: 'Decode VIN encountered an unknown error. Please try again later',
          });
        }
      })
  }

  openMissingDataModal() {
    axios.get(urlConstants.BASE_URL + '/' + this.props.type + '/lookupByEmail?type=user' +
      '&email=' + encodeURIComponent(this.state.userEmail))
    .then(userRes => {
      let userDetailsFound = userRes.data.Items[0];
      userDetailsFound.email = this.state.userEmail;
      axios.get(urlConstants.BASE_URL + '/vehicle/vin?vin=' + encodeURIComponent(this.state.vin))
      .then(vehicleRes => {
        let vehicle = vehicleRes.data.Item;
        let profileRef = React.createRef();
        let vehicleRef = React.createRef();
        let profileModal = <ProfileModal type={this.props.type} profileInfo={userDetailsFound}
                                    targetGroup='user'
                                    successCallback={data => {
                                      this.closeModal();
                                    }}
                                    ref={profileRef}
                                    hideUpdate={true} />;
        let vehicleModal = <VehicleInfoModal type={this.props.type} vehicleInfo={vehicle}
                                             extractedVehicleInfo={vehicle}
                                             successCallback={data => {
                                               this.closeModal();
                                             }}
                                             ref={vehicleRef}
                                             hideUpdate={true}/>
        this.setState({
          modalComponent: <div>
            {profileModal}
            {vehicleModal}
            <Button color="primary" onClick={() => {
              let promise1 = profileRef.current.updateUserInfo();
              let promise2 = vehicleRef.current.updateVinInfo();
              Promise.all([promise1, promise2]).then(() => {
                alert('successfully updated all info')
                this.closeModal();
              })
            }} > Update All</Button>
          </div>,
          modalOpen: true
        });
      });
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false
    });
  }

  toggleCCCOne() {
    this.setState({
      cccOneEnabled: !this.state.cccOneEnabled,
    });
  }

  getFromXpath(node) {
    let rVal = '';
    if (node) {
      rVal = node.firstChild.data;
    }
    return rVal;
  }

  pullFromCCCOne() {
    axios.get(urlConstants.BASE_URL + '/facility/cccone/estimate?estimateId=' + this.state.estimateId)
    .then(res => {
      let xml = res.data.replace(/\s/g, '');
      let doc = new DOMParser().parseFromString(xml);
      let firstName = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /PersonName /FirstName', doc, true));
      let lastName = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /PersonName /LastName', doc, true));
      let email = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /CommEmail', doc, true));
      let vin = this.getFromXpath(xpath.select('/VehicleInfo /VINInfo /VIN /VINNum', doc, true));
      let licensePlate = this.getFromXpath(xpath.select('/VehicleInfo /License /LicensePlateNum', doc, true));
      let year = this.getFromXpath(xpath.select('/VehicleInfo /VehicleDesc /ModelYear', doc, true));
      let make = this.getFromXpath(xpath.select('/VehicleInfo /VehicleDesc /MakeDesc', doc, true));
      let model = this.getFromXpath(xpath.select('/VehicleInfo /VehicleDesc /ModelName', doc, true));
      let color = this.getFromXpath(xpath.select('/VehicleInfo /Paint /Exterior /Color /ColorName', doc, true));
      let body = this.getFromXpath(xpath.select('/VehicleInfo /Body /BodyStyle', doc, true));
      let miles = this.getFromXpath(xpath.select('/EventInfo /RepairEvent /ArrivalOdometerReading', doc, true));
      let phoneNum = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /CommPhone', doc, true));
      let invoiceAmount = this.getFromXpath(xpath.select('/RepairTotalsInfo /SummaryTotalsInfo /TotalAmt', doc, true));
      let address1 = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /Address /Address1', doc, true));
      let address2 = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /Address /Address2', doc, true));
      let city = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /Address /City', doc, true));
      let state = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /Address /StateProvince', doc, true));
      let zipCode = this.getFromXpath(xpath.select('/AdminInfo /Owner /Party /PersonInfo /Communications /Address /PostalCode', doc, true));
      let address;
      if (address2) {
        address = address1 + ', ' + address2 + ', ' + city + ', ' + state + ' ' + zipCode;
      } else {
        address = address1 + ', ' + city + ', ' + state + ' ' + zipCode;
      }

      this.setState({
        userEmail: email,
        name: firstName + " " + lastName,
        vin: vin,
        showVehicleDetails: true,
        licenseNum: licensePlate,
        miles: miles,
        vehicleDetails: year + " " + make + " " + model + " " + color + " " + body,
        phoneNum: phoneNum,
        address: address,
        invoiceAmount: invoiceAmount,
      })
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.returnToDashboard) {
      return <Redirect to={"/admin/dashboard?vin=" + this.state.vin} push={true} />
    }
    if (this.state.open) {
      return <Redirect to="/dashboard" push={true} />
    }

    return (
      <div className="container" id="transactionContainer" >
        <form onSubmit={this.handleSubmit}>
          <Card>
            {this.state.communicationType === 'onboard' &&
              <CardHeader classes="vin-card" color="primary" style={{
                fontWeight: 'bold',
                fontSize: '23px'
              }}>Onboard VIN-User</CardHeader>
            }
            {this.state.communicationType !== 'onboard' &&
              <CardHeader classes="vin-card" color="primary" style={{
                fontWeight: 'bold',
                fontSize: '23px'
              }}>{this.state.communicationType.charAt(0).toUpperCase() + this.state.communicationType.slice(1)}
              </CardHeader>
            }
            <CardBody>
              {!this.props.disableContactType &&
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/*<select value={this.state.communicationType} onChange={this.typeChange} >
                    {this.props.type !== 'user' && <option value={'authorization'}>Authorization</option>}
                    <option value={'message'}>Message</option>
                  </select>*/}
                  <div className="FormField full">
                    <label className="FormField__Label" htmlFor="enrollment">Contact type:&#9;</label>
                    {/*<select className="fddsaf" name="enrollment" id="enrollment" value={this.state.enrollmentType} onChange={this.typeChange}>
                        <option value="manager">Facility Manager Enrollment</option>
                        <option value="employee">Facility Employee Enrollment</option>
                      </select>*/}
                    <div className="form-check">
                      <input className="form-check-input" value="authorization" type="radio" name="communicationType" id="flexRadioDefault1" onChange={this.handleRadioChange}/>
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Authorization
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" value="message" type="radio" name="communicationType" id="flexRadioDefault2" onChange={this.handleRadioChange} defaultChecked/>
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Message
                      </label>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              }
              <GridContainer onSubmit={this.handleSubmit} xs={12} style={{width: "60%", float: "left"}}>
                {false && <GridItem xs={5}>
                  <Button style={{marginTop: '35px'}} onClick={() => this.toggleCCCOne()}>Import from CCC One</Button>
                </GridItem>}
                {this.state.cccOneEnabled &&
                  <GridItem xs={7}>
                    <CustomInput
                      labelText="CCC ONE Estimate ID"
                      inputProps={{
                        name: "estimateId",
                        value: this.state.estimateId || '',
                        onChange: this.handleChange
                      }}
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      />
                    <Button onClick={() => this.pullFromCCCOne()}>Import</Button>
                  </GridItem>
                }
                <GridItem xs={12} >
                  <CustomInput
                    labelText="Vin"
                    inputProps={{
                      name:"vin",
                      value:this.state.vin || '',
                      onChange:this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                {this.state.showVehicleDetails &&
                <GridItem xs={12}>
                  <CustomInput
                    labelText='License #'
                    inputProps={{
                      name: "licenseNum",
                      value: this.state.licenseNum || '',
                      onChange: this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}/>
                </GridItem>
                }
                {this.state.showVehicleDetails &&
                <GridItem xs={12}>
                  <CustomInput
                    labelText='Details'
                    inputProps={{
                      name: "vehicleDetails",
                      value: this.state.vehicleDetails || '',
                      onChange: this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                }
                {this.state.showVehicleDetails &&
                    <GridItem xs={12}>
                      <CustomInput
                          labelText='Miles'
                          inputProps={{
                            name: "miles",
                            value: this.state.miles || '',
                            onChange: this.handleChange
                          }}
                          formControlProps={{
                            fullWidth: true,
                            required: true
                          }} />
                    </GridItem>
                }
                {this.props.type !== 'user' && this.state.communicationType === 'onboard' &&
                <GridItem xs={12} >
                  <Button onClick={() => this.decodeVin()} >Decode Vin</Button>
                  {this.state.decodeVinWarning && <p className='decode_tag'>{this.state.decodeVinWarning}</p>}
                </GridItem>}
                <GridItem xs={12} >
                  <CustomInput
                    labelText={this.state.communicationType === 'message' ? "User email (use commas for more than 1 email)" : "User email (one only)"}
                    inputProps={{
                      name:"userEmail",
                      value:this.state.userEmail,
                      onChange:this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                {this.props.type !== 'user' && this.state.communicationType === 'message' &&
                <Button onClick={() => this.populateEmails()} >All Signed In (enter vin first)</Button> }
                <GridItem xs={12} >
                  <CustomInput labelText="Customer/Business Name"
                               inputProps={{
                                 name:"name",
                                 value:this.state.name || '',
                                 onChange:this.handleChange
                               }}
                               formControlProps={{
                                 fullWidth: true,
                                 required: true
                               }} />
                </GridItem>
                {this.state.address !== undefined &&
                <GridItem xs={12}>
                  <CustomInput
                    labelText='Address'
                    inputProps={{
                      name:"address",
                      value:this.state.address,
                      onChange:this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                }
                {this.state.phoneNum !== undefined &&
                <GridItem xs={12}>
                  <CustomInput
                    labelText='Phone #'
                    inputProps={{
                      name:"phoneNum",
                      value:this.state.phoneNum,
                      onChange:this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                }
                {this.state.communicationType !== 'onboard' &&
                <GridItem xs={12}>
                  <CustomInput
                    labelText={this.props.type === 'user' ? "Quick Message" : "RO # & Quick Message"}
                    inputProps={{
                      name:"subjectLine",
                      value:this.state.subjectLine,
                      onChange:this.handleChange
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }} />
                </GridItem>
                }
                {this.state.invoiceAmount !== undefined && this.state.communicationType !== 'message' &&
                  <Grid container={true} >
                    <GridItem xs={12}>
                      {!this.state.toggleActive &&
                      <CustomInput
                        labelText="Invoice Amount"
                        inputProps={{
                          name: "invoiceAmount",
                          type: 'number',
                          value: this.state.invoiceAmount,
                          step: 0.01,
                          onChange: this.handleChange,
                        }}
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}/>
                      }
                    </GridItem>
                    {this.state.paymentSetupWarning &&
                      <p>{this.state.paymentSetupWarning}</p>
                    }
                  </Grid>
                }
                </GridContainer>
                <GridContainer xs={12} style={{float: 'right', width: "40%"}}>
                  <Dropzone onDrop={files => {
                    this.extractPdf(files);
                    this.setState({
                      files: files
                    });
                  }} noClick={true} accept='application/pdf, image/*, video/*'>
                    {({getRootProps, getInputProps, open}) => (
                      <>
                      <div className='drop_area' {...getRootProps()}>
                        <GridItem className="dropzone-grid-section" xs={12}>
                          <div onClick={open}>
                            <p>Drag pdf file here or click here to add (upto 10) files.</p>
                            <h4>Click Here</h4>
                          </div>
                          <input {...getInputProps()} type="file" id='pdfService' onChange={(e) => {
                            const files = Array.from(e.target.files);
                            this.extractPdf(files);
                            this.setState({
                              files: files,
                            });
                          }} multiple />
                          <ul>{this.state.files.map(file => {
                            let objectURL = URL.createObjectURL(file);

                            return <div>
                              <li><a href={objectURL} download={file.name}>{file.name}</a></li>
                              <Button onClick={(ev) => {
                                ev.preventDefault();
                                this.setState({
                                  files: this.state.files.slice(0, this.state.files.indexOf(file)).concat(this.state.files.slice(this.state.files.indexOf(file) + 1, this.state.files.length)),
                                  extractionWarning: '',
                                })
                              }} justIcon={true}><Close /></Button>
                            </div>
                          })}</ul>
                          {this.state.extractionWarning && this.state.extractionWarning === extractionLoading &&
                          <LinearLoadingDeterminate milliseconds={10000} />}
                          {this.state.extractionWarning && this.state.extractionWarning !== extractionLoading &&
                            <p style={{color: this.state.extractionWarning === extractionFailed ? 'red' : 'black'}}>{this.state.extractionWarning}</p>}
                         
                        </GridItem>
                      </div>
                      <p className='drop_area_tagline'>Note: hold down ctrl key to select multiple files (you can only select multiple files if they
                            are in the same folder).</p>
                      </>
                      
                    )}
                  </Dropzone>
                </GridContainer>
              </CardBody>
              <CardFooter>
                {!this.state.submitEnabled && 'Uploading... This may take a minute if you have selected large files'}
                {this.state.isSubmitted && <img src={checkbox} />}
                {this.state.error && <p>{this.state.error}</p> }
                <Box sx={{m: 1, position: 'relative'}}>
                  <Button className="btn-btn-btn" color="primary" type="submit" disabled={!this.state.submitEnabled}>Submit Event</Button>
                  {!this.state.submitEnabled &&
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '30%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  }
                </Box>
              </CardFooter>
          </Card>
        </form>
        <Modal isOpen={this.state.modalOpen} style={{overlay: {zIndex: 1000}}} >
          <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
          {this.state.modalComponent}
        </Modal>
      </div>
    );
  }
}
export default withStyles(styles)(NewTransaction);