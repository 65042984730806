// @material-ui/icons
import MoneyIcon from "@material-ui/icons/Money";
import Person from "@material-ui/icons/Person";
import customer from "./layouts/members.png";
import ArchiveIcon from "./assets/img/archive.png";
import DashboardIcon from "./assets/img/dashboard.png";
import NewUserIcon from "./assets/img/new_user.png";
import StripeIcon from "./assets/img/strip.png";
import BillingIcon from "./assets/img/billing.png";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import NewTransaction from "views/Transaction/NewTransaction.jsx"
import UserProfile from "views/UserProfile/UserProfile.jsx";
import React from "react";
import Archive from "./views/Archive/Archive";
import Billing from "./views/Billing/Billing";

function dashboardRouteSelector(type) {
  let routePrepend = '';
  if (type === 'insurance') {
    routePrepend = '/insurance';
  } else if (type === 'user') {
    routePrepend = '/user'
  }

  const dashboardRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      render: (props) => <DashboardPage {...props} type={type} />,
      layout: routePrepend + "/admin"
    },
    {
      path: "/user",
      name: "Profile",
      icon: Person,
      render: (props) => <UserProfile {...props} type={type} />,
      layout: routePrepend + "/admin"
    },
  ];

  if (type !== 'user') {
    dashboardRoutes.splice(0, 0, {
      path: "/uploadTransaction",
      name: "New VIN-User",
      icon: NewUserIcon,
      render: (props) => <NewTransaction {...props} disableContactType={true} name='Customer' type={type} />,
      layout: routePrepend + "/admin"
    });
    dashboardRoutes.push({
      path: "/archive",
      name: "Archive",
      icon: ArchiveIcon,
      render: (props) => <Archive {...props} type={type} />,
      layout: routePrepend + "/admin",
    });
    dashboardRoutes.push({
      path: "/billing",
      name: "Billing",
      icon: BillingIcon,
      render: (props) => <Billing {...props} type={type} />,
      layout: routePrepend + "/admin",
    })
    // dashboardRoutes.push({
    //   path: '/directPayments',
    //   name: 'Facility to Facility payments',
    //   icon: AttachMoneyIcon,
    //   render: (props) => <DirectPayment {...props} />,
    //   layout: routePrepend + "/admin",
    // })
  }

  return dashboardRoutes;
}

export default dashboardRouteSelector;
